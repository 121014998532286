import React, { Fragment, useState, useEffect, Component, useRef } from "react"
import axios from "axios";
import Cookies from "universal-cookie";
import { Form, Button, Grid, Message, List } from "semantic-ui-react"
import styled from "styled-components"

import Checkbox from "@material-ui/core/Checkbox";

import arrow_outline from "../images/arrow-outline.svg"

import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget"
import AddressAutoComplete from "../components/AddressAutoComplete"

import Table from 'react-bootstrap/Table';


const PPMOfferingSummary = props => {

  return (
    <div>
      <H1Text>I. SUMMARY OF THE OFFERING</H1Text>

      <PText>
      {props.llc_name}, a {props.city} limited liability company (the “Company”), is
      offering investors the opportunity to acquire Membership Interests (the “Interests”) in the
      Company (the “Offering”). The Company is seeking commitments from Investors to invest up
      to approximately ${props.maximum_offering} in the aggregate (the “Funding Commitment”). Investors will
      be expected to contribute their entire individual Funding Commitment upon execution and
      acceptance of the Subscription Agreement by the Company.
      The Company intends to invest the entire amount of investor capital (less expenses
      described herein) to acquire and improve the real property located at and commonly known as
      {props.address1} {props.address2} {props.city}, {props.state} {props.zip} {props.country} (the “Property”). Approximately {props.percentage_towards_renovations} of
      investor capital will be contributed as a down payment towards the purchase of the Property with
      the remaining purchase price financed by an institutional lender mortgage. The remaining
      investor capital will be utilized for improvements to the Property. The Property is developed as
      a {props.bedroom}-bedroom, {props.bathroom}-bathroom, {props.sqft} sq. ft. {props.property_type} located in {props.city}, {props.state}. The
      Company intends make the Property available for {props.rental_type}, and views the acquisition of

      the Property as a value-add opportunity that is expected to generate steadily-increasing cash-on-
      cash returns for its Investors.

      The Offering is being made pursuant to Section 4(a)(2) of the Securities Act of 1933 as
      amended (the “Act”) and Rule 506 thereunder. The following is a summary of the principal
      terms of the Offering. This summary is qualified in all respects by the terms of this Confidential
      Executive Summary and the Company’s Operating Agreement.
      </PText>
      <PText>

      </PText>
      <BasicTable 
        maximum_offering={props.maximum_offering}
        subscription_period={props.subscription_period}
        post_closing={props.post_closing}
        />

        <P1Text>
          State and Date of Organization: 
        </P1Text>
        <PText>
          The Company is a {props.state} limited liability company organized in {props.start_date} pursuant to the {props.state} Limited Liability Company Act, as amended from time to time (the “Act”)
        </PText>

        <P1Text>
          Company Business:
        </P1Text>
        <PText>
          The Company intends to invest the amount of cash available for investment(net of organizational expenses and offering costs), as specified in the table entitled “Use of Proceeds,” to purchase, improve, and operate the Property.
        </PText> 

        <P1Text>
          Management:
        </P1Text>
        <PText>
          The Company will be managed by {props.manager_first_name} {props.manager_last_name}. (the “Manager”), {props.manager_details}
        </PText> 

        <P1Text>
          Additional Capital Calls: 
        </P1Text>
        <PText>
          The Manager may elect to make additional mandatory capital calls in such amounts and for such purposes as the Manager may determine in its discretion. 
          Additional capital calls will be made by the Members in proportion to their respective Membership Interests.
        </PText> 

        <P1Text>
          Distributions from Capital Events. 
        </P1Text>
        <PText>
          Upon a “Capital Event” with respect to the Property (i.e.,
          sale or refinance), the Company intends to distribute the net proceeds from such Capital Event to
          the Members in an amount that would return their invested capital. The remaining net proceeds
          will be distributed to the Members in proportion to their respective Membership Interests.
        </PText> 

        <P1Text>
          Management Fee.
        </P1Text>
        <PText>
          The Manager will receive an annual management fee of {props.manager_fee} of the
          Company’s gross revenues. However, the Manager may waive or reduce this management fee
          for such time period as it determines in its sole discretion.
        </PText> 

        <P1Text>
          Distributions to Members.
        </P1Text>
        <PText>
          Upon generating any operating income with respect to the Property,
          the Company intends to distribute the net proceeds to the Members in proportion to their
          respective Membership Interests.
        </PText> 

        <H1Text>There is no assurance that the Company will generate Distributable Cash or other assets
          available for distribution to the Members. 
        </H1Text>

        <P1Text>
          Anticipated Holding Period:
        </P1Text>
        <PText>
          The Company provides no assurance as to the holding period
          during which investors may be required to hold their investment in order to maximize cash
          returns before a disposition by the Company at peak performance. The anticipated holding
          period is subject to changes in market factors. If any Member requests to sell his Membership
          Interest, the Manager of the Company will make reasonable efforts to assist in such sale, but
          there is no assurance that the Company will be able to find purchasers of the investors’ interests. 
        </PText> 

        <P1Text>
          Who Should Invest:
        </P1Text>
        <PText>
            An investment in the Company involves certain risks. There is not expected
            to be any public market for the Membership Interests. An investment in the Company by a          
            qualified pension or retirement plan involves certain additional factors which should be considered
            by the fiduciary prior to making the investment.
        </PText> 

        <P1Text>
          Offering:
        </P1Text>
        <PText>
           The Membership Interests are being offered to a limited number of potential investors
          known to the Company, the Manager and certain others who are founders of the Company, and
          no other Person has been authorized by the Company to sell the interests. The Offering will
          close upon securing subscriptions for the minimum offering; provided, however, the Manager
          may, in its discretion, extend the closing and/or conduct additional closing(s). See “Subscription
          Procedures.”
        </PText> 

    </div>
  )
}

export default PPMOfferingSummary;


function BasicTable(props) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
{/*          <th>Nature of Investment...................................</th>
          <th>Funding Commitment ...............................</th>*/}
{/*          <th>Proceeds to Company*</th>
          <th>Subscription Period..............................................</th>
          <th>Post-Closing Capitalization.................................</th>*/}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Nature of Investment...................................</td>
          <td>Membership Interests</td>
        </tr>
        <tr>
          <td>Funding Commitment ...............................</td>
          <td>{props.maximum_offering}</td>
        </tr>
        <tr>
          <td>Proceeds to company * ...............................</td>
          <td>See Use of Proceeds below.</td>
        </tr>
        <tr>
          <td>Subscription Period..............................................</td>
          <td>{props.subscription_period}</td>
        </tr>
        <tr>
          <td>Post-Closing Capitalization.................................</td>
          <td>{props.post_closing}</td>
        </tr>
        <tr>
          <td>Use of Proceeds.............................</td>
          <td>The company seeks {props.maximum_offering} to fund the purchase of and improvements to the Property and to fund administrative and organizational activities of the Company, including legal fees. See below.</td>
        </tr>
      </tbody>
    </Table>
  );
}

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  
  border-radius: 3px;
  transition: all 150ms;
`

const Body = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:#F0F2F5;

`

const Tbackground = styled.div`
 
`

const TopBackground1 = styled.img`
  
`

const TopBackground = styled.img`
  
`

const PText = styled.p`
  text-align: left;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-style: normal;  
`

const P1Text = styled.p`
  text-align: left;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-weight: 600;
`

const Logo = styled.img`
 
`

const TopBackground2 = styled.img`
  
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  text-align: center;
`

const HText = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size:75px;
  text-align: center;
`

const Hero = styled.div`
  
`
const Hero2 = styled.div`
  
`

const Hero3 = styled.div`
  height:519px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
  
`

const BackButton = styled.label`
  // backgroundImage:arrow_outline;
  background-image: url(${arrow_outline});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: "#4D566F";

  background-color: white;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`

const Label = styled.label`
  
`

const Input = styled.input`
  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`
