import React, { useState } from "react";
import styled from "styled-components"
import { data } from "./Data";
import Form from "./Form";
import ReadOnly from "./ReadOnly";
import EditRow from "./EditRow";
import Pagination from "./Pagination";
import Filter from "./Filter";

const TableApp = (props) => {
  const [tableData, setTableData] = useState(data); // input data for table
  const [toggle, setToggle] = useState(null); //for data ids
  const [search, setSearch] = useState(""); //for filtering data
  const [editData, setEditData] = useState({
    // for editing data
    fullName: "",
    userName: "",
    address: "",
    phoneNumber: "",
    email: "",
    website: "",
    companyName: "",
  });
  const [order, setOrder] = useState("asc"); //for sorting
  const [number, setNumber] = useState(1); // No of pages
  const [postPerPage] = useState(4);

  const Delete = (del) => {
    const delData = tableData.filter((tbd) => {
      return del !== tbd.id;
    });
    setTableData(delData);
  };

  const Edit = (data) => {
    // for opening the editable row
    setToggle(data);
    setEditData(data);
  };

  const Cancel = () => {
    setToggle(null);
  };

  const Sort = (sort) => {
    if (order === "asc") {
      const sorted = [
        ...tableData.sort((a, b) => (a[sort] > b[sort] ? 1 : -1)),
      ];
      console.log(sorted);
      setTableData(sorted);
      setOrder("desc");
    } else if (order === "desc") {
      const sorted = [
        ...tableData.sort((a, b) => (a[sort] < b[sort] ? 1 : -1)),
      ];
      console.log(sorted);
      setTableData(sorted);
      setOrder("asc");
    }
  };
  //for Pagination
  const lastPost = number * postPerPage;
  const firstPost = lastPost - postPerPage;
  const currentData = tableData.slice(firstPost, lastPost);

  return (
    <>
      <div >
        <div >
          {/*<h1 >Add Investors</h1>*/}
          {/*<Filter search={search} setSearch={setSearch} />*/}
          <form>
            <Hero3 >
              <thead>
                <tr>
                  <th id="tr" onClick={() => Sort("fullName")}>
                    Name
                  </th>
                  <th id="tr" onClick={() => Sort("email")}>
                    Email
                  </th>
{/*                  <th id="tr" onClick={() => Sort("userName")}>
                    User Name
                  </th>*/}
                  <th id="tr" onClick={() => Sort("phoneNumber")}>
                    Phone Number
                  </th>
{/*                  <th id="tr" onClick={() => Sort("website")}>
                    Website
                  </th>*/}
{/*                  <th id="tr" onClick={() => Sort("companyName")}>
                    Company Name
                  </th>*/}

                  <th id="tr">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentData
                  .filter((val) => {
                    if (search === "") {
                      return val;
                    } else if (
                      val.userName.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((data) => {
                    return (
                      <>
                        {toggle === data ? (
                          <EditRow
                            key={data.id}
                            tableData={tableData}
                            setTableData={setTableData}
                            Cancel={Cancel}
                            setEditData={setEditData}
                            editData={editData}
                          />
                        ) : (
                          <ReadOnly
                            key={data.id}
                            data={data}
                            currentData={currentData}
                            Edit={Edit}
                            Delete={Delete}
                          />
                        )}
                      </>
                    );
                  })}
              </tbody>
            </Hero3>
          </form>

{/*          <Pagination
            number={number}
            setNumber={setNumber}
            tableData={tableData}
            postPerPage={postPerPage}
          />*/}

          <h1 >{props.title}</h1>
          <Form tableData={tableData} setTableData={setTableData} />
        </div>
      </div>
    </>
  );
};

export default TableApp;


const Hero3 = styled.table`
  // height:519px;
  // position:relative;
  // width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  // border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  // box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`