import React, { Fragment, useState, useEffect, Component, useRef } from "react"
import axios from "axios";
import Cookies from "universal-cookie";
import { Form, Button, Grid, Message, List } from "semantic-ui-react"
import styled from "styled-components"

import Checkbox from "@material-ui/core/Checkbox";

import arrow_outline from "../images/arrow-outline.svg"

import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget"
import AddressAutoComplete from "../components/AddressAutoComplete"


const PPMInvestorSuitabilityStandards = props => {

  return (
    <div>
      <H1Text>
        IV. INVESTOR SUITABILITY STANDARDS
      </H1Text>

      <PText>
        Prospective investors should independently satisfy themselves that an investment in the
        Membership Interests (“Interests”) is suitable for them, should examine this Offering
        Memorandum and all of its Exhibits and enclosures, and should obtain any additional
        information about this Offering and the Company that they consider necessary to make an
        informed investment decision.
        Because of the inability to withdraw funds from the Company and the risks of investment (some
        of which are discussed under “Risk Factors”), each investor must have funds adequate to meet
        personal needs and contingencies, must have no need for prompt liquidity from the investment,
        and must purchase Interests for investment only and not with a view to their sale or distribution.
        Each investor must also have sufficient knowledge and experience in financial and business
        matters generally and in securities investment in particular to be capable of evaluating the merits
        and risks of investing in the Company.
        The purchase of Interests is probably not a suitable investment for tax qualified plans (such as
        IRAs, Keogh plans and profit sharing plans), unless such plans are willing to be subject to tax on
        unrelated business taxable income. Fiduciaries of tax qualified plans, in consultation with their
        tax and legal advisers, should carefully consider whether an investment in Interests is consistent
        with their fiduciary responsibilities, particularly the responsibilities outlined in Part 4 of Title I of
        ERISA. QUALIFIED PLANS ARE URGED TO CONSULT WITH THEIR LEGAL,
        FINANCIAL AND TAX ADVISERS BEFORE INVESTING IN MEMBERSHIP INTERESTS.
        Investors that are subject to income tax should be aware that investment in the Company may (if
        the Company is successful) to create taxable income or tax liabilities in excess of cash
        distributions available to pay such liabilities. Accordingly, the Interests may not be a suitable
        investment for prospective investors who will be subject to and do not desire such consequences.
        </PText>
        <PText>
          Investor Suitability Standards
        </PText>
        <PText>
        Membership Interests may be sold to any number of “accredited” purchasers and up to 35 nonaccredited purchasers. Accreditation standards are described in the Investor Questionnaire.
        Representations and requests for information regarding the satisfaction of investor suitability
        standards are included in the Subscription Agreement and Investor Questionnaire that each
        prospective investor must complete. The Interests have not been registered under the 1933 Act
        and are being offered in reliance on Sections 3(b) and 4(a)(2) thereof and Regulation D
        promulgated by the SEC thereunder, and in reliance on applicable exemptions from state law
        registration or qualification provisions. Prior to selling the Interests to any offeree, the Company
        intends to make all inquiries reasonably necessary to satisfy itself that the prerequisites of such
        exemptions have been met. Prospective investors will also be required to provide whatever
        additional evidence is deemed necessary by the Company to substantiate information or
        representations contained in their Subscription Agreements and Investor Questionnaires.
        The standards set forth above are only minimum standards. The Company reserves the right to
        reject the Subscription Agreement of any prospective investor for whom it appears, in the
        exclusive discretion of the Company, the Membership Interests may not be a suitable investment. 
      </PText>
    </div>
  )
}

export default PPMInvestorSuitabilityStandards;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  
  border-radius: 3px;
  transition: all 150ms;
`

const Body = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:#F0F2F5;

`

const Tbackground = styled.div`
 
`

const TopBackground1 = styled.img`
  
`

const TopBackground = styled.img`
  
`

const PText = styled.p`
  text-align: center;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-style: normal;

  
`

const Logo = styled.img`
 
`

const TopBackground2 = styled.img`
  
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  text-align: center;
`

const HText = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size:75px;
  text-align: center;
`

const Hero = styled.div`
  
`
const Hero2 = styled.div`
  
`

const Hero3 = styled.div`
  height:519px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
  
`

const BackButton = styled.label`
  // backgroundImage:arrow_outline;
  background-image: url(${arrow_outline});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: "#4D566F";

  background-color: white;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`

const Label = styled.label`
  
`

const Input = styled.input`
  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`
