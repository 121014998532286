import React, { Fragment, useState, useEffect, Component, useRef } from "react"
import StepWizard from "react-step-wizard"
import 'animate.css';

import axios from "axios";
import Cookies from "universal-cookie";

import transitions from '../components/transitions.css';
import animated from '../components/animated.less';
import enterRight from '../components/enterRight.less';
import intro from '../components/intro.less';
import enterLeft from '../components/enterLeft.less';
import exitRight from '../components/exitRight.less';
import exitLeft from '../components/exitLeft.less';

// import usePlacesWidget from "react-google-autocomplete";

import { Form, Button, Grid, Message, List } from "semantic-ui-react"

// import Autocomplete from "react-google-autocomplete";

import Nav from "../components/Wizard2/nav"
import Plugs from "../components/Wizard2/Plugs"
import NavV2 from "../components/NavV2"
import PlacesPage from "../components/PlacesPage"
import PropertyInput from "../components/PropertyInput"
import LLCInput from "../components/LLCInput"

import PPMInput from "../components/PPMInput"

import SecureLogin from "../components/SecureLogin"

import { sendCloudCastles,getBothTokens } from '../components/Helpers'

import AddressAutoComplete from "../components/AddressAutoComplete"

// import Gallery from "../components/Gallery"
import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget"
import ProfileInput from "../components/ProfileInput"

import GlobalFonts from "../font/fonts"

import white_logo_text from "../images/white_logo_text.png"
import black_logo_text from "../images/black_logo_text.png"

import arrow_outline from "../images/arrow-outline.svg"

import topbackground from "../assets/TopPolygon1.svg"
import topbackground2 from "../assets/TopPolygon2.svg"

import styled from "styled-components"

import Helmet from "react-helmet"
import { graphql } from "gatsby";
import { useForm } from "react-hook-form";

// import InputGoogleMaps from "../components/InputGoogleMaps"
// import DefaultDropdown from "../components/DefaultDropdown"
import TableApp from "../components/table/TableApp"
// import Login from "../components/Login"

import ResponsiveAppBar from "../components/ResponsiveAppBar"

import { Script } from "gatsby"

import ProgressBar from 'react-bootstrap/ProgressBar';

import CottageIcon from '@mui/icons-material/Cottage';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import IconButton from "@mui/material/IconButton";

// google maps api
// AIzaSyAvRViF7bYdQ50zH5jUblEcZlyXndw7aJs

import {
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
  FacebookMessengerIcon,
  LinkedinIcon,
  LivejournalIcon,
  PinterestIcon,
  LinkedinShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"
import SEO from "../components/seo"

/**
 * A basic demonstration of how to use the step wizard
 */
var obj = {}
var email = ""

const Wizard = () => {
  const [progress,set_progress] = useState(0)
  
    const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: `${animated} ${enterRight}`,
            enterLeft: `${animated} ${enterLeft}`,
            exitRight: `${animated} ${exitRight}`,
            exitLeft: `${animated} ${exitLeft}`,
            intro: `${animated} ${intro}`,
        },
        // demo: true, // uncomment to see more
    });

  const updateForm = (key, value) => {
    const { form } = state

    form[key] = value
    updateState({
      ...state,
      form,
    })
  }

  // Do something on step change
  const onStepChange = stats => {
    console.log(stats["activeStep"])

    const p = (stats["activeStep"]/10) * 100
    set_progress(p)
    // console.log(instance)

    // var axios = require("axios")
    // var data = JSON.stringify({
    //   email: obj["email"],
    //   dataFields: obj,
    // })

    // console.log("data")
    // console.log(data)

    // // frontend iterable
    // var config = {
    //   method: "post",
    //   url: "https://api.iterable.com/api/users/update",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Api-Key": "dd373517425447ca8ab5d993282d17ab",
    //   },
    //   data: data,
    // }

    // axios(config)
    //   .then(function (response) {
    //     console.log(JSON.stringify(response.data))
    //     // setEmailSent("We'll send you info!")
    //   })
    //   .catch(function (error) {
    //     console.log(error)
    //   })

  }

  const setInstance = SW =>
    updateState({
      ...state,
      SW,
    })

  const { SW, demo } = state

    const custom = {
      enterRight: `${animated} ${enterRight}`,
      enterLeft: `${animated} ${enterLeft}`,
      exitRight: `${animated} ${exitRight}`,
      exitLeft: `${animated} ${exitLeft}`,
      intro: `${animated} ${intro}`,
    }

    // const transitions = {
    //   enterRight: 'animated fadeInDown',
    //   enterLeft : 'animated fadeInDown',
    //   exitRight : 'animated fadeInDown',
    //   exitLeft  : 'animated fadeInDown'
    // }
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Cloud Castles Vacation Homes</title>
        <meta
          name="google-site-verification"
          content="lRDxE-lZUVoBRi5yV2AHoVkcaALQME9xGG-XwQYIbcU"
        />{" "}
        <meta
          name="keywords"
          content="Vacation home Real estate investing, is real estate investing worth it, is real estate investing a business,how to start real estate investing"
        />
        <meta
          name="description"
          content="Allowing Anyone To Own Anywhere. Your go to Vacation home Real estate investing company."
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10871406798"
        ></script>
        <script type="application/ld+json">{`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('config', 'AW-10871406798');
  `}</script>
     
      </Helmet>
      <GlobalFonts />
      <ResponsiveAppBar />
      <Body>
        <Hero>
          <div >
            <div>
            {/*<div>*/}
              <div>
              {/*<div>*/}
                <StepWizard
                  // transitions={state.transitions}                   // comment out for default transitions
                  onStepChange={onStepChange}
                  isHashEnabled

                  // nav={<Nav />}
                  instance={setInstance}
                  // transitions={custom}
                >
                
                  <Landing />
                  {/*<AddressAutoComplete endpoint={`${process.env.CASTLE_API_URL}/v1/web_registration/`}/>*/}
                  <FirstInput hashKey={"FirstStep"} update={updateForm} />
                  
                  <Rental_Q1 />
                  <Second />
                  <ProfileInput />
                  {/*<UserAddition />*/}
                  {/*<LLCInput />*/}
                  <PropertyInput  update={updateForm} />
                  {/*<PPMInput />*/}
                </StepWizard>
              </div>
            </div>
          </div>
          {demo && SW && <InstanceDemo SW={SW} />}
        </Hero>
      </Body>
       <ProgressBar variant="info" now={progress} />
    </>
  )
}


export default Wizard

/** Demo of using instance */
const InstanceDemo = ({ SW }) => (
  <Fragment>

  </Fragment>
)

/**
 * Stats Component - to illustrate the possible functions
 * Could be used for nav buttons or overview
 */

const dataForLeads = {
  test: "test",
}

const Stats = ({
  currentStep,
  firstStep,
  goToStep,
  lastStep,
  nextStep,
  previousStep,
  totalSteps,
  step,
}) => (
  <div>
    <hr />
    {step > 1 && (
      <CTAButton  onClick={previousStep}>
        Go Back
      </CTAButton>
    )}
    {step < totalSteps ? (
      <CTAButton onClick={nextStep}>
        Continue
      </CTAButton>
    ) : (
      <CTAButton onClick={nextStep}>
        Finish
      </CTAButton>
    )}
    <hr />
    {/* <div style={{ fontSize: '21px', fontWeight: '200' }}> */}
    {/*     <h4>Other Functions</h4> */}
    {/*     <div>Current Step: {currentStep}</div> */}
    {/*     <div>Total Steps: {totalSteps}</div> */}
    {/*     <button className='btn btn-block btn-default' onClick={firstStep}>First Step</button> */}
    {/*     <button className='btn btn-block btn-default' onClick={lastStep}>Last Step</button> */}
    {/*     <button className='btn btn-block btn-default' onClick={() => goToStep(2)}>Go to Step 2</button> */}
    {/* </div> */}
  </div>
)

/** Steps */
const Landing = props => {
    const nextSteppers = () => {
    props.nextStep()
    }



  // const { ref } = usePlacesWidget({
  //   apiKey: "AIzaSyAvRViF7bYdQ50zH5jUblEcZlyXndw7aJs",
  //   onPlaceSelected: (place) => console.log(place)
  // })

    return (
    <Hero3>
      <SEO
        title="Own a Home"
        keywords="Vacation home Real estate investing, is real estate investing worth it, is real estate investing a business,how to start real estate investing"
      />
      <GlobalFonts />
      <H1Text>It's Easy To Start Your Real Estate Business</H1Text>
      <P1Text>
        1 Setup all your legal paperwork
      </P1Text>
      <PText>
        Register your LLC and decide the terms of the investors.
      </PText>
      <P1Text>
        2 Invite your investment group 
      </P1Text>
      <PText>
        Build your team of investors and share the details of the investment
      </PText>
      <P1Text>
        3 Launch your property investment group 
      </P1Text>
      <PText>
        Grab funds and close on your investment 
      </PText>
      <CTAButton onClick={nextSteppers}>Sign Up</CTAButton>
    </Hero3>
  )
}

const FirstInput = props => {
  const [emailError, setEmailError] = React.useState("")

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [password, setPassword] = useState("");
  const [signUpUsername, setSignUpUsername] = useState("");
  const [signUpPW, setSignUpPW] = useState("");
  const [signUpPW2, setSignUpPW2] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [response, setResponse] = useState(null);

  let userObj = {}
  // const [userObj,setUserObj] = useState({});

  const cookies = new Cookies();

  const update = e => {
    props.update(e.target.name, e.target.value)
    // console.log(e.target.name, e.target.value)
    userObj[e.target.name] = e.target.value

    console.log(userObj)
    console.log("first_name")
    console.log(userObj["first_name"])

    console.log("last_name")
    console.log(userObj["last_name"])

    console.log("email")
    console.log(userObj["email"])

    setEmail(userObj["email"])
    setFirstName(userObj["first_name"])
    setLastName(userObj["last_name"])
    setPhoneNumber(userObj["phoneNumber"])

// Temporary password

  }

  const handleSumit = (event) => {
    // use to make sure page isn't reloaded when we send off content
    event.preventDefault();

    var randomNumber = Math.random().toString(36).substr(2, 8);
    setSignUpPW2(randomNumber);
    setSignUpPW(randomNumber);

    const loginData = {
      password1: randomNumber,
      password2: randomNumber,
      username: email,
      email: email,
    };

    const userData = {
      first_name: first_name,
      last_name: last_name,
      phoneNumber: phoneNumber,
      email: email,
    };

    console.log(userData);
    console.log(loginData);


    
    axios
      .post(`${process.env.CASTLE_API_URL}/rest-auth/registration/`, loginData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
          console.log(response.data);

          const castle_key = response.data["key"];          
          // cookies.set("castle_key", castle_key, { path: "/", httpOnly:true });

          console.log("WTFError");
          // console.log(castle_key);

          // sendCloudCastles(`${process.env.CASTLE_API_URL}/v1/web_registration/`,userData)
          

          axios
            .put(`${process.env.CASTLE_API_URL}/v1/web_registration/`, userData, {
              headers: {
                "Content-Type": "application/json",
                Authorization: "token " + castle_key,
              },
            })
            .then((response) => {
                console.log(response.data);
                getBothTokens(email,randomNumber)
                props.nextStep()
              })
            .catch((error) => {
              console.log(error.response.data);
              setEmailError(error.response.data.email)
            });
        })
      .catch((error) => {
        console.log(error.response.data);
        setEmailError(error.response.data.email)
      });
    };

  const nextSteppers = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(obj.email)) {
      props.nextStep()
    } else {
      console.log(email)

      setEmailError("Please set a valid email")
    }
  }

  const previousSteppers = () => {
    props.previousStep()
  }

  return (
    <Hero3>
      <SEO
        title="Own a Home"
        keywords="Vacation home Real estate investing, is real estate investing worth it, is real estate investing a business,how to start real estate investing"
      />
      <H1Text>Start Your Real Estate Business</H1Text>
      <PText>
        Setup all your legal paperwork, register your LLC and pool funding from investors all on one easy to use platfrom.
      </PText>
      {/*<PlacesPage />*/}
      <Hero2>

        <CTAInput
          type="text"
          className="form-control"
          name="first_name"
          placeholder="First Name"
          onChange={event => setFirstName(event.target.value)}
        />

        {/*<PText>Last Name</PText>*/}
        <CTAInput
          type="text"
          className="form-control"
          name="last_name"
          placeholder="Last Name"
          onChange={event => setLastName(event.target.value)}
        />
        {/*<PText>Phone Number</PText>*/}
        <CTAInput
          type="tel"        
          className="form-control"
          name="business_type"
          placeholder="555-555-5555"
          onChange={event => setPhoneNumber(event.target.value)}
        />
        {/*<PText>Email</PText>*/}
        <CTAInput
          type="text"
          className="form-control"
          name="email"
          placeholder="Email"
          onChange={event => setEmail(event.target.value)}
          error={emailError}
        />
        <span style={{ color: "red" }}>{emailError}</span>
      </Hero2>
      <CTAButton onClick={handleSumit}>Next Step</CTAButton>
      <BackButton  onClick={previousSteppers}>Back</BackButton>
    </Hero3>
  )
}

const Rental_Q1 = ({ props, nextStep,previousStep }) => {

  const cottag = <CottageIcon></CottageIcon>;
  const beach = <BeachAccessIcon></BeachAccessIcon>;
  const multi = <HolidayVillageIcon></HolidayVillageIcon>;

  const validate = () => {
    if (window.confirm("Are you sure you want to go back?")) {
      // eslint-disable-line
      props.previousStep()
    }
  }
  const questions = [
    {
      questionText: "Let's invest in a:", 
      answerOptions: [
        {
          "icon":beach,
          answerText: "Beach House",
          key: "rental_type",
          value: "new_rental",
        },
        {
          "icon":cottag,
          answerText: "Getaway Home",
          key: "rental_type",
          value: "current_rental",
        },
        {
          "icon":multi,
          answerText: "Multi-Family Building",
          key: "rental_type",
          value: "invite",
        },
      ],
    }
  ]

  const [currentQuestion, setCurrentQuestion] = useState(0)

  const nextSteppers = props => {
    nextStep()
  }
  const previousSteppers = props => {
    previousStep()
  }

  const handleAnswerOptionClick = (answerText, key, value) => {

    obj[key] = value
    console.log(obj)

    const nextQuestion = currentQuestion + 1
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion)
    } else {
      nextSteppers()
    }
  }
  return (
    <Hero3>
        <div className="question-section">
          <div className="question-count">
            {/*<span>Question {currentQuestion + 1}</span>/{questions.length}*/}
          </div>
          <H1Text>{questions[currentQuestion].questionText}</H1Text>
        </div>

          {questions[currentQuestion].answerOptions.map(answerOption => (
            <IconButton

              onClick={() =>
                handleAnswerOptionClick(
                  answerOption.isCorrect,
                  answerOption.answerText,
                  answerOption.key,
                  answerOption.value
                )
              }
            >{answerOption.icon}
              {answerOption.answerText}
            </IconButton>
          ))}
      <BackButton  onClick={previousSteppers}>Back</BackButton>
    </Hero3>
  )
}

const Second = ({ props, nextStep,previousStep }) => {

  const cottag = <CottageIcon></CottageIcon>;
  const beach = <BeachAccessIcon></BeachAccessIcon>;
  const multi = <HolidayVillageIcon></HolidayVillageIcon>;

  const validate = () => {
    if (window.confirm("Are you sure you want to go back?")) {
      // eslint-disable-line
      props.previousStep()
    }
  }
  const questions = [
    {
      questionText: "Let's invest in a:", 
      answerOptions: [
        {
          "icon":beach,
          answerText: "Beach House",
          key: "rental_type",
          value: "new_rental",
        },
        {
          "icon":cottag,
          answerText: "Getaway Home",
          key: "rental_type",
          value: "current_rental",
        },
        {
          "icon":multi,
          answerText: "Multi-Family Building",
          key: "rental_type",
          value: "invite",
        },
      ],
    }
  ]

  const [currentQuestion, setCurrentQuestion] = useState(0)

  const nextSteppers = props => {
    nextStep()
  }
  const previousSteppers = props => {
    previousStep()
  }

  const handleAnswerOptionClick = (answerText, key, value) => {

    obj[key] = value
    console.log(obj)

    const nextQuestion = currentQuestion + 1
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion)
    } else {
      nextSteppers()
    }
  }
  return (
    <Hero3>
        <div className="question-section">
          <div className="question-count">
            {/*<span>Question {currentQuestion + 1}</span>/{questions.length}*/}
          </div>
          <H1Text>{questions[currentQuestion].questionText}</H1Text>
        </div>

          {questions[currentQuestion].answerOptions.map(answerOption => (
            <IconButton

              onClick={() =>
                handleAnswerOptionClick(
                  answerOption.isCorrect,
                  answerOption.answerText,
                  answerOption.key,
                  answerOption.value
                )
              }
            >{answerOption.icon}
              {answerOption.answerText}
            </IconButton>
          ))}
      <BackButton  onClick={previousSteppers}>Back</BackButton>
    </Hero3>
  )
}

// class Property extends Component {

const UserAddition = props => {
  const [value, setValue] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleMenuOne = () => {
    // do something
    setOpen(false);
  };

  const handleMenuTwo = () => {
    // do something
    setOpen(false);
  };

  const onSubmit = () => {
        console.log("Success!");
    };
      const previousSteppers = () => {
    props.previousStep()
  }

    useEffect(() => {
      console.log(value)

    }, []);

    const nextSteppers = () => {
    // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // if (re.test(obj.email)) {
      props.nextStep()
    // } else {
    //   console.log(email)

    //   setEmailError("Please set a valid email")
    // }
  }



    return (
      <>
      <Hero3>
        <H1Text>Invite Co-Investors</H1Text>
            <TableApp title={"Add Investors"}/>
            <CTAButton onClick={nextSteppers}>Next Step</CTAButton>
            <BackButton  onClick={previousSteppers}>Back</BackButton>
      </Hero3>
      </>
    )
}

// const PropertyInput = props => {
//   const [emailError, setEmailError] = React.useState("")
//   const update = e => {
//     props.update(e.target.name, e.target.value)
//     // console.log(e.target.name, e.target.value)

//     obj[e.target.name] = e.target.value
//     const queryString = window.location.search;
//     const urlParams = new URLSearchParams(queryString);
//     const _utm_medium = urlParams.get('utm_medium')
//     const _utm_souce = urlParams.get('utm_souce')
//     const _utm_campaign = urlParams.get('utm_campaign')
//     const _utm_term = urlParams.get('utm_term')

//     obj.utm_medium = _utm_medium
//     obj.utm_source = _utm_souce
//     obj.utm_campaign = _utm_campaign
//     obj.utm_term = _utm_term
//     console.log(obj)
//   }

//   const nextSteppers = () => {
//     //     alert("test");
//     //

//     let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//     if (re.test(obj.email)) {
//       props.nextStep()
//     } else {
//       console.log(email)

//       setEmailError("Please set a valid email")
//     }
//   }

//   return (
//     <div>
//       <H1Text>Property Details</H1Text>
//       <PText>
//         Setup all your legal paperwork, register your LLC and pool funding from investors all on one easy to use platfrom.
//       </PText>
//       {/*<PlacesPage />*/}
//       <Hero2>
//         <PText>LLC Name</PText>
//         <CTAInput
//           type="text"
//           className="form-control"
//           name="llc_name"
//           placeholder="Business Name"
//           onChange={update}
//         />

//         <PText>State</PText>
//         <CTAInput
//           type="text"
//           className="form-control"
//           name="last_name"
//           placeholder="Last Name"
//           onChange={update}
//         />
//         <PText>Total Amount To Raise</PText>
//         <CTAInput
//           type="tel"        
//           className="form-control"
//           name="total_amount"
//           placeholder="$10000"
//           onChange={update}
//         />
//         <PText>Email</PText>
//         <CTAInput
//           type="text"
//           className="form-control"
//           name="email"
//           placeholder="Email"
//           onChange={update}
//           error={emailError}
//         />
//         <span style={{ color: "red" }}>{emailError}</span>
//       </Hero2>
//       <CloudinaryUploadWidget />
//       <CTAButton onClick={nextSteppers}>Next Step</CTAButton>
//     </div>
//   )
// }


export function sendToPartners(email, data) {
  var axios = require("axios")
  var data = JSON.stringify({
    email: email,
    dataFields: { source: "alpha_sign_up_web" },
  })

  // alert(email)
  // frontend iterable
  //  var config = {
  //    method: "post",
  //    url: "https://api.iterable.com/api/users/update",
  //    headers: {
  //      "Content-Type": "application/json",
  //      "Api-Key": "dd373517425447ca8ab5d993282d17ab",
  //    },
  //    data: data,
  //  }
  //
  //  axios(config)
  //    .then(function (response) {
  //      console.log(JSON.stringify(response.data))
  //      // setEmailSent("We'll send you info!")
  //      return response
  //    })
  //    .catch(function (error) {
  //      console.log(error)
  //      return error
  //    })
  //
}

const Body = styled.div`
  // height: 100vh;
  padding-top:100px;
  padding-bottom:100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:#F0F2F5;
`

const Tbackground = styled.div`
 
`

const TopBackground1 = styled.img` 
`

const TopBackground = styled.img`
  
`

const PText = styled.p`
  text-align: center;
  // padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-style: normal;
`

const P1Text = styled.p`
  text-align: left;
  // padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-weight: 600;
`

const Logo = styled.img`
 
`

const TopBackground2 = styled.img`
  
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
`

const Hero = styled.div`
  
`
const Hero2 = styled.div`
  
`

const Hero3 = styled.div`
  height:519px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
  
`

const BackButton = styled.label`
  // backgroundImage:arrow_outline;
  background-image: url(${arrow_outline});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: "#4D566F";

  background: clear;
  // box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  // border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`

const Label = styled.label`
  
`

const Input = styled.input`
  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`

