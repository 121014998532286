import React, { useState,useEffect } from "react";
import styled from "styled-components"
import profile_anon from "../images/profile_anon.png"
import ImagePlaceholder from "../images/ImagePlaceholder.png"


export default function CloudinaryUploadWidget(props) {
  const [imageUrl, setimageUrl] = useState(ImagePlaceholder);
  const [imageAlt, setimageAlt] = useState(null);

  useEffect(() => {

    if (props.profileImg){
      setimageUrl(props.profileImg)
    }

    }, []);

  const handleImageUpload = () => {
    setimageUrl(profile_anon)

    const { files } = document.querySelector('input[type="file"]');

    const imageFile = document.querySelector('input[type="file"]');
    // destructure the files array from the resulting object
    const filesa = imageFile.files;
    // log the result to the console
    console.log("Image file", filesa[0]);

    const formData = new FormData();
    formData.append("file", files[0]);
    // replace this with your upload preset name
    formData.append("upload_preset", "cyyfgj8e");
    const options = {
      method: "POST",
      body: formData,
    };

    // replace cloudname with your Cloudinary cloud_name
    return fetch(
      "https://api.Cloudinary.com/v1_1/du1shuqio/image/upload",
      options
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("res.secure_url")
        console.log(res.secure_url)
        props.setimageUrlParent(res.secure_url)
        setimageUrl(res.secure_url);
        setimageAlt(`An image of ${res.original_filename}`);

      })
      .catch((err) => console.log(err));
  };


  //removed becuase window is not in gatsby
  // const openWidget = () => {
  //   // create the widget
  //   const widget = window.cloudinary.createUploadWidget(
  //     {
  //       cloudName: "du1shuqio",
  //       uploadPreset: "cyyfgj8e",
  //     },
  //     (error, result) => {
  //       if (result.event === "success") {
  //         setimageUrl(result.info.secure_url);
  //         setimageAlt(`An image of ${result.info.original_filename}`);
  //       }
  //     }
  //   );
  //   widget.open(); // open up the widget after creation
  // };

  return (
    <div className="app">
      <section className="left-side">
        <form>
          <div className="form-group">
            <input type="file" onChangeCapture={handleImageUpload} />
          </div>

{/*removed*/}
{/*          <button type="button" className="btn" onClick={handleImageUpload}>
            Submit
          </button>*/}
{/*          <button type="button" className="btn widget-btn" onClick={openWidget}>
            Upload Via Widget
          </button>*/}
        </form>
      </section>
      <section className="right-side">
        
        {imageUrl && (
          <StartImage src={imageUrl} alt={imageAlt} className="displayed-image" />
        )}
        {/*<a>The resulting image will be displayed here</a>*/}
      </section>
    </div>
  );
}


const StartImage = styled.img` 
  height:100px;
  width:100px;
  border-radius:33px;
  object-fit: cover;

`


// import React, { Component } from "react";

// class CloudinaryUploadWidget extends Component {
//   componentDidMount() {
//     const cloudName = "du1shuqio"; // replace with your own cloud name
//     const uploadPreset = "cyyfgj8e"; // replace with your own upload preset

//     // Remove the comments from the code below to add
//     // additional functionality.
//     // Note that these are only a few examples, to see
//     // the full list of possible parameters that you
//     // can add see:
//     //   https://cloudinary.com/documentation/upload_widget_reference

//     var myWidget = window.cloudinary.createUploadWidget(
//       {
//         cloudName: cloudName,
//         uploadPreset: uploadPreset
//         // cropping: true, //add a cropping step
//         // showAdvancedOptions: true,  //add advanced options (public_id and tag)
//         // sources: [ "local", "url"], // restrict the upload sources to URL and local files
//         // multiple: false,  //restrict upload to a single file
//         // folder: "user_images", //upload files to the specified folder
//         // tags: ["users", "profile"], //add the given tags to the uploaded files
//         // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
//         // clientAllowedFormats: ["images"], //restrict uploading to image files only
//         // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
//         // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
//         // theme: "purple", //change to a purple theme
//       },
//       (error, result) => {
//         if (!error && result && result.event === "success") {
//           console.log("Done! Here is the image info: ", result.info);
//           document
//             .getElementById("uploadedimage")
//             .setAttribute("src", result.info.secure_url);
//         }
//       }
//     );
//     document.getElementById("upload_widget").addEventListener(
//       "click",
//       function () {
//         myWidget.open();
//       },
//       false
//     );
//   }

//   render() {
//     return (
//       <button id="upload_widget" className="cloudinary-button">
//         Upload
//       </button>
//     );
//   }
// }

// export default CloudinaryUploadWidget;
