import React, { Fragment, useState, useEffect, Component, useRef } from "react"
import axios from "axios";
import Cookies from "universal-cookie";
import { Form, Button, Grid, Message, List } from "semantic-ui-react"
import styled from "styled-components"

import Checkbox from "@material-ui/core/Checkbox";

import arrow_outline from "../images/arrow-outline.svg"

import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget"
import AddressAutoComplete from "../components/AddressAutoComplete"


const PPMSubscriptionAgreement = props => {

  return (
    <div>
      <H1Text>
        SUBSCRIPTION AGREEMENT
      </H1Text>

      <P1Text>
        To: {props.llc_name} LLC, a {props.state} limited liability company
      </P1Text>
      <PText> 
        I have received and had an opportunity to read (and have my legal, business and tax advisors
        review, to the extent I deem appropriate) the Offering Memorandum (the “Memorandum”) by
        which the Membership Interests (the "Interests") are offered. I represent that I am purchasing the
        Interests for my own account (joint or trust account, as may be shown below), in compliance with
        any state restrictions regarding the size of my investment as compared to my net worth and/or
        income, and not with a view to or for sale in connection with any distribution. I understand that
        this subscription is irrevocable, and that my subscription will not be returned unless the Company
        rejects my subscription or the minimum offering is not achieved as set forth in the Memorandum.
        The Company shall have the right to reject this subscription if it reasonably believes that the
        undersigned is not an accredited investor under Regulation D promulgated under the Securities
        Act of 1933, as amended, or fails to meet the suitability standards for investment in the Company
        as set forth in the Memorandum, or for any other reason. Acceptance is evidenced only by the
        execution of this instrument by the Company in the space provided below. I agree to indemnify
        the Company and its officers against any loss in the event that the information furnished to the
        Company or the representations I have made are incorrect.
      </PText>

      <P1Text>
        Signature
      </P1Text>

      <P1Text>
        Today's Date: {props.todays_date}
      </P1Text>

      <H1Text>
        Funds Committed: {props.investor_funds_committed}
      </H1Text>

      <P1Text>
        Register the Interests in the following name(s): {props.investor_first_name} {props.investor_last_name}
      </P1Text>

      <P1Text>
        NO SUBSCRIPTION AGREEMENT IS EFFECTIVE UNTIL ACCEPTANCE by: {props.llc_name} a {props.state} limited liability company.
      </P1Text>
        
      <PText> 
        As: {props.investor_type}
      </PText> 

      <PText> 
        Mailing Address:  {props.investor_address1} {props.investor_address2} {props.investor_city} {props.investor_state} {props.investor_zip}
      </PText> 
      <PText>   
        Phone Number:  {props.investor_phoneNumber}
      </PText>   
      <PText> 
        Social Security or Taxpayer ID Number: {props.investor_ssn_or_ein}
      </PText> 

      <PText> 
        By the manager: {props.manager_first_name} {props.manager_last_name}
      </PText> 

      <P1Text>
        Manager Signature
      </P1Text>

    </div>
  )
}

export default PPMSubscriptionAgreement;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  
  border-radius: 3px;
  transition: all 150ms;
`

const Body = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:#F0F2F5;

`

const Tbackground = styled.div`
 
`

const TopBackground1 = styled.img`
  
`

const TopBackground = styled.img`
  
`

const PText = styled.p`
  text-align: center;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-style: normal;
`

const P1Text = styled.p`
  text-align: left;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-weight: 600;
`

const Logo = styled.img`
 
`

const TopBackground2 = styled.img`
  
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  text-align: center;
`

const HText = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size:75px;
  text-align: center;
`

const Hero = styled.div`
  
`
const Hero2 = styled.div`
  
`

const Hero3 = styled.div`
  height:519px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
  
`

const BackButton = styled.label`
  // backgroundImage:arrow_outline;
  background-image: url(${arrow_outline});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: "#4D566F";

  background-color: white;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`

const Label = styled.label`
  
`

const Input = styled.input`
  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`
