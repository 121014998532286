import React, { Fragment, useState, useEffect, Component, useRef } from "react"
import axios from "axios";
import Cookies from "universal-cookie";
import { Form, Button, Grid, Message, List } from "semantic-ui-react"
import styled from "styled-components"

import Checkbox from "@material-ui/core/Checkbox";

import arrow_outline from "../images/arrow-outline.svg"

import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget"
import AddressAutoComplete from "../components/AddressAutoComplete"


const PPMInvestorQuestionnaire = props => {

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log(checked)
  };

  return (
    <div>
      <H1Text>
        SHORT FORM INVESTOR QUESTIONNAIRE
      </H1Text>

      <P1Text>
        I represent that I am an Accredited Investor because I meet the following definition of an
        “Accredited Investor” as defined by Rule 501 of the Securities Act of 1933.
      </P1Text>

      <Checkbox checked={checked} onChange={handleChange} />
      <PText> 
        Any bank as defined in Section 3(a)(2) of the Securities Act, or any savings and loan association
        or other institution as defined in Section 3(a)(5)(A) of the Securities Act, whether acting in its individual or
        fiduciary capacity; any broker or dealer registered pursuant to Section 15 of the Securities Exchange Act of
        1934; any insurance company as defined in Section 2(13) of the Securities Act; any investment company
        registered under the Investment Company Act of 1940 or a business development company as defined in
        Section 2(a)(48) of that Act; any small business investment company licensed by the U.S. Small Business
        Administration under Section 3018 or (d) of the Small Business Investment Act of 1958; any plan
        established and maintained by a state or its political subdivisions for the benefit of its employee benefit plan
        within the meaning of the Employee Retirement Income Security Act of 1974 if the investment decision is
        made by a plan fiduciary, as defined in Section 3(21) of such Act, which is either a bank, savings and loan
        association, insurance company, or registered investment advisor, or if the employee benefit plan has total
        assets in excess of $5,000,000 or, if a self-directed plan, with investment decisions made solely by persons
        that are accredited investors.
      </PText>

      <Checkbox checked={checked} onChange={handleChange} />
      <PText> 
        Any private business development co. as defined in Section 202(a)(22) of the 1940 Investment
        Advisors Act.
      </PText>

      <Checkbox checked={checked} onChange={handleChange} />
      <PText> 
        Any not-for-profit organization described in Section 501(c)(3) of the Internal Revenue Code, or
        any Massachusetts or similar business trust or partnership not formed for the specific purpose of acquiring
        the securities offered, with total assets in excess of $5,000,000;
      </PText>

      <Checkbox checked={checked} onChange={handleChange} />
      <PText> 
        Any Manager, executive officer, or general partner of the issuer of the securities being offered or
        sold, or any Manager, executive officer, or general partner of a general partner that is a issuer;
      </PText>

      <Checkbox checked={checked} onChange={handleChange} />
      <PText> 
        Any natural person whose individual net worth, or joint net worth with that person's spouse, at the
        time of his purchase exceeds $1,000,000;
      </PText>

      <Checkbox checked={checked} onChange={handleChange} />
      <PText> 
        Any natural person who had an individual income in excess of $200,000 in each of the two most
        recent years or joint income with that person's spouse in excess of $300,000 in each of those years and has
        a reasonable expectation of reaching the same income level in the current year;
      </PText>

      <Checkbox checked={checked} onChange={handleChange} />
      <PText> 
        Any trust, with total assets in excess of $5,000,000 not formed for the specific purpose of acquiring
        the securities offered, whose purchase is directed by a sophisticated person as described in Rule
        506(b)(2)(ii) of Reg. D;
      </PText>

      <Checkbox checked={checked} onChange={handleChange} />
      <PText> 
        Any entity in which all of the equity owners are accredited investors.
      </PText>

      <P1Text>
        Signature
      </P1Text>

      <P1Text>
        Today's Date: {props.todays_date}
      </P1Text>

      <P1Text>
        Register the Interests in the following name(s): {props.investor_first_name} {props.investor_last_name}
      </P1Text>


    </div>
  )
}

export default PPMInvestorQuestionnaire;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  
  border-radius: 3px;
  transition: all 150ms;
`

const Body = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:#F0F2F5;

`

const Tbackground = styled.div`
 
`

const TopBackground1 = styled.img`
  
`

const TopBackground = styled.img`
  
`

const PText = styled.p`
  text-align: center;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-style: normal;
`

const P1Text = styled.p`
  text-align: left;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-weight: 600;
`

const Logo = styled.img`
 
`

const TopBackground2 = styled.img`
  
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  text-align: center;
`

const HText = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size:75px;
  text-align: center;
`

const Hero = styled.div`
  
`
const Hero2 = styled.div`
  
`

const Hero3 = styled.div`
  height:519px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
  
`

const BackButton = styled.label`
  // backgroundImage:arrow_outline;
  background-image: url(${arrow_outline});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: "#4D566F";

  background-color: white;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`

const Label = styled.label`
  
`

const Input = styled.input`
  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`
