import React, { Fragment, useState, useEffect, Component, useRef } from "react"
import axios from "axios";
import Cookies from "universal-cookie";
import { Form, Button, Grid, Message, List } from "semantic-ui-react"
import styled from "styled-components"

import Checkbox from "@material-ui/core/Checkbox";

import arrow_outline from "../images/arrow-outline.svg"

import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget"
import AddressAutoComplete from "../components/AddressAutoComplete"

import HorizontalTimelineProp from "../components/HorizontalTimelineProp"

import PPMCoverPage from "../components/PPMCoverPage"
import PPMTitlePage from "../components/PPMTitlePage"
import PPMLegalDisclosures from "../components/PPMLegalDisclosures"
import PPMOfferingSummary from "../components/PPMOfferingSummary"
import PPMUseOfProceeds from "../components/PPMUseOfProceeds"
import PPMRiskFactors from "../components/PPMRiskFactors"
import PPMInvestorSuitabilityStandards from "../components/PPMInvestorSuitabilityStandards"
import PPMSubscriptionProcedures from "../components/PPMSubscriptionProcedures"
import PPMSubscriptionAgreement from "../components/PPMSubscriptionAgreement"
import PPMInvestorQuestionnaire from "../components/PPMInvestorQuestionnaire"



const jsonForPPMCover = {
  "llc_name":"Really Happy LLC",
  "address1":"123 Fun St",
  "address2":"Suite 420",
  "city":"Awesomeville",
  "state":"Arkanass",
  "zip":"696969",
  "country":"US",
  "start_date":"Jan 1st, 2023",
  "bedroom":"4",
  "bathroom":"4",
  "sqft":"1,503",
  "todays_date":"Jan 1st, 2023",
  "manager":{
    "first_name":"Cloud",
    "last_name":"Castles , Inc",
    "email":"support@cloudcastles.io",
    "phoneNumber":"1-510-896-9364",
    "manager_details":"a Delaware corporation formed in December 2019. Jimmy Woodard and Bryan Marks are the executive officers of Cloud Castles, Inc.",
    "manager_fee":"5%",
  },
  "investment":{
    "maximum_offering":"$10,000,000",
    "percentage_towards_renovations":"65%",
    "subscription_period":"Each Member shall contribute its total subscription to the Company upon execution of the Subscription Agreement and acceptance by the Company.",
    "post_closing":"The Members will own 100% of the Membership Interests. The Membership Interests will have limited voting rights associated with them.",

    "down_payment":"$3,000,000",
    "organizational_offering_expenses":"500,000",
    "capital_improvements":"$6,500,000",
    
  },
  "investor":{
    "first_name":"Mr.",
    "last_name":"CloudyMcFace",
    "email":"CloudyMcFace@cloudcastles.io",
    "phoneNumber":"1-510-896-9364",
    
    "ssn":"128323232",

    "address1":"123 Fun St",
    "address2":"Suite 420",
    "city":"Awesomeville",
    "state":"Arkanass",
    "zip":"696969",
    "country":"US",

    "funds_committed":"$16,000",
    "investor_type":"individual",
  }
}

const PPMInput = props => {
  const [llc_name,set_llc_name] = React.useState("")

  const [address1,setAddress1] = React.useState("");
  const [address2,setAddress2] = React.useState("");
  const [city,setCity] = React.useState("");
  const [state,setState] = React.useState("");
  const [zip,setZip] = React.useState("");
  const [country, setCountry] = useState("US");

  const [start_date,set_start_date] = React.useState("");
  const [todays_date,set_todays_date] = React.useState("");

  const [bedroom,set_bedroom]= useState("")
  const [bathroom,set_bathroom]= useState("")
  const [sqft,set_sqft]= useState("")

  const [manager_email, set_manger_email] = useState("");
  const [manager_first_name,set_manager_first_name] = useState("");
  const [manager_last_name,set_manager_last_name] = useState("");
  const [manager_phone_number,set_manager_phone_number] = useState("");
  const [manager_details,set_manager_details]= useState("");
  const [manager_fee,set_manager_fee]= useState("");

//investment
  const [maximum_offering,set_maximum_offering] = useState("");
  const [percentage_towards_renovations,set_percentage_towards_renovations] = useState("");
  const [subscription_period,set_subscription_period] = useState("");
  const [post_closing,set_post_closing] = useState("");
  const [down_payment,set_down_payment] = useState("");
  const [organizational_offering_expenses,set_organizational_offering_expenses] = useState("");
  const [capital_improvements,set_capital_improvements] = useState("");

//investor 
  const [investor_first_name,set_investor_first_name] = useState("");
  const [investor_last_name,set_investor_last_name] = useState("");
  const [investor_email,set_investor_email] = useState("");
  const [investor_phoneNumber,set_investor_phoneNumber] = useState("");

  const [investor_ssn_or_ein,set_investor_ssn_or_ein] = useState("");

  const [investor_address1,set_investor_address1] = useState("");
  const [investor_address2,set_investor_address2] = useState("");
  const [investor_city,set_investor_city] = useState("");
  const [investor_state,set_investor_state] = useState("");
  const [investor_zip,set_investor_zip] = useState("");
  const [investor_country,set_investor_country] = useState("");

  const [investor_funds_committed,set_investor_funds_committed] = useState("");  
  const [investor_type,set_investor_type] = useState("");  

  let userObj = {}
  // const [userObj,setUserObj] = useState({});

  const cookies = new Cookies();

  const update = e => {
    props.update(e.target.name, e.target.value)
    // console.log(e.target.name, e.target.value)
    userObj[e.target.name] = e.target.value
  // Temporary password

  }

  const handleSumit = (event) => {
    // use to make sure page isn't reloaded when we send off content
    event.preventDefault();

    };

  const nextSteppers = () => {
      props.nextStep()
  }

  const previousSteppers = () => {
    props.previousStep()
  }

  useEffect(() => {
    //LLC 
    set_llc_name(jsonForPPMCover.llc_name)

    setAddress1(jsonForPPMCover.address1)
    setAddress2(jsonForPPMCover.address2)
    setCity(jsonForPPMCover.city)
    setState(jsonForPPMCover.state)
    setZip(jsonForPPMCover.zip)
    setCountry(jsonForPPMCover.country)

    set_start_date(jsonForPPMCover.start_date)
    set_todays_date(jsonForPPMCover.todays_date)

    //  Manager
    set_manger_email(jsonForPPMCover.manager.email)
    set_manager_first_name(jsonForPPMCover.manager.first_name)
    set_manager_last_name(jsonForPPMCover.manager.last_name)
    set_manager_phone_number(jsonForPPMCover.manager.phoneNumber)
    set_manager_details(jsonForPPMCover.manager.manager_details)
    set_manager_fee(jsonForPPMCover.manager.manager_fee)

    // Property
    set_bedroom(bedroom)
    set_bathroom(bathroom)
    set_sqft(sqft)
    set_maximum_offering(jsonForPPMCover.investment.maximum_offering)
    set_percentage_towards_renovations(jsonForPPMCover.investment.percentage_towards_renovations)
    set_subscription_period    (jsonForPPMCover.investment.subscription_period)
    set_post_closing(jsonForPPMCover.investment.post_closing)
    set_down_payment(jsonForPPMCover.investment.down_payment)
    set_organizational_offering_expenses(jsonForPPMCover.investment.organizational_offering_expenses)
    set_capital_improvements(jsonForPPMCover.investment.capital_improvements)

    set_investor_first_name(jsonForPPMCover.investor.first_name)
    set_investor_last_name(jsonForPPMCover.investor.last_name)
    set_investor_email(jsonForPPMCover.investor.email)
    set_investor_phoneNumber(jsonForPPMCover.investor.phoneNumber)

    set_investor_ssn_or_ein(jsonForPPMCover.investor.ssn)

    set_investor_address1(jsonForPPMCover.investor.address1)
    set_investor_address2(jsonForPPMCover.investor.address2)
    set_investor_city(jsonForPPMCover.investor.city)
    set_investor_state(jsonForPPMCover.investor.state)
    set_investor_zip(jsonForPPMCover.investor.zip)
    set_investor_country(jsonForPPMCover.investor.country)

    set_investor_funds_committed(jsonForPPMCover.investor.funds_committed)
    set_investor_type(jsonForPPMCover.investor.investor_type)

  });


  return (
    <Hero>
      {/*<HorizontalTimelineProp />*/}
      <H1Text>PPM Creation</H1Text>
      <PPMCoverPage 
        llc_name={llc_name} 
        address1={address1}
        address2={address2}
        city={city}
        state={state}
        zip={zip}
        country={country}

        manager_first_name={manager_first_name}
        manager_last_name={manager_last_name}
        manager_email={manager_email}
        manager_phone_number={manager_phone_number}
      />

      <PPMTitlePage 
        maximum_offering={maximum_offering}

        manager_first_name={manager_first_name}
        manager_last_name={manager_last_name}
        manager_email={manager_email}
        manager_phone_number={manager_phone_number}
      />

      <PPMLegalDisclosures 
        llc_name={llc_name} 
      />

      <PPMOfferingSummary 
        llc_name={llc_name} 
        address1={address1}
        address2={address2}
        city={city}
        state={state}
        zip={zip}
        country={country}

        start_date={start_date}
        bedroom={bedroom}
        bathroom={bathroom}
        sqft={sqft}

        manager_first_name={manager_first_name}
        manager_last_name={manager_last_name}
        manager_email={manager_email}
        manager_phone_number={manager_phone_number}
        manager_details={manager_details}
        manager_fee={manager_fee}

        maximum_offering={maximum_offering}
        percentage_towards_renovations={percentage_towards_renovations}
        subscription_period={subscription_period}
        post_closing={post_closing}
        down_payment={down_payment}
        organizational_offering_expenses={organizational_offering_expenses}
        capital_improvements={capital_improvements}
      />

      <PPMUseOfProceeds
        maximum_offering={maximum_offering}
        percentage_towards_renovations={percentage_towards_renovations}
        subscription_period={subscription_period}
        post_closing={post_closing}
        down_payment={down_payment}
        organizational_offering_expenses={organizational_offering_expenses}
        capital_improvements={capital_improvements}
      />

        <PPMRiskFactors
         manager_first_name={manager_first_name}
         manager_last_name={manager_last_name}
        />

        <PPMInvestorSuitabilityStandards 

        />

        <PPMSubscriptionProcedures

        />

        <PPMSubscriptionAgreement
         llc_name={llc_name} 
         address1={address1}
         address2={address2}
         city={city}
         state={state}
         zip={zip}
         country={country}            

         investor_first_name={investor_first_name}
         investor_last_name={investor_last_name}
         investor_email={investor_email}
         investor_phoneNumber={investor_phoneNumber}

         investor_ssn_or_ein={investor_ssn_or_ein}

         investor_address1={investor_address1}
         investor_address2={investor_address2}
         investor_city={investor_city}
         investor_state={investor_state}
         investor_zip={investor_zip}
         investor_country={investor_country} 
         todays_date={todays_date}
         investor_funds_committed={investor_funds_committed}
         investor_type={investor_type}

         manager_first_name={manager_first_name}
         manager_last_name={manager_last_name}
        />

        <PPMInvestorQuestionnaire
         investor_first_name={investor_first_name}
         investor_last_name={investor_last_name}
         todays_date={todays_date}
        />
    </Hero>
  )
}

export default PPMInput;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  
  border-radius: 3px;
  transition: all 150ms;
`

const Body = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:#F0F2F5;

`

const Tbackground = styled.div`
 
`

const TopBackground1 = styled.img`
  
`

const TopBackground = styled.img`
  
`

const PText = styled.p`
  text-align: center;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-style: normal;

  
`

const Logo = styled.img`
 
`

const TopBackground2 = styled.img`
  
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
`

const Hero = styled.div`
  margin-right:10%;
  margin-left:10%;
  
`
const Hero2 = styled.div`
  
`

const Hero3 = styled.div`
  height:519px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
  
`

const BackButton = styled.label`
  // backgroundImage:arrow_outline;
  background-image: url(${arrow_outline});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: "#4D566F";

  background-color: white;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`

const Label = styled.label`
  
`

const Input = styled.input`
  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`
