import React, { Fragment, useState, useEffect, Component, useRef } from "react"
import axios from "axios";
import Cookies from "universal-cookie";
import { Form, Button, Grid, Message, List } from "semantic-ui-react"
import styled from "styled-components"

import Checkbox from "@material-ui/core/Checkbox";

import arrow_outline from "../images/arrow-outline.svg"

import Table from 'react-bootstrap/Table';


const PPMUseOfProceeds = props => {

  return (
    <div>
      <H1Text>
        II. USE OF PROCEEDS
      </H1Text>
      <PText>
        The amounts set forth below are estimates only. There can be no assurances that the actual
        amounts expended on various categories will not vary from the amounts set forth below.
      </PText>

      <BasicTable 

        maximum_offering={props.maximum_offering}
        down_payment={props.down_payment}
        organizational_offering_expenses={props.organizational_offering_expenses}
        capital_improvements={props.capital_improvements}

      />

      <PText>
      “Organizational and Offering Expenses” are defined as estimated legal, accounting,
      printing, filing fees for securities registration and entity formation, and other Offering
      expenses. 
      </PText>
    </div>
  )
}

export default PPMUseOfProceeds;

function BasicTable(props) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Total Subscription/ Capital Commitments</td>
          <td>{props.maximum_offering}</td>
        </tr>
        <tr>
          <td>Down Payment</td>
          <td>{props.down_payment}</td>
        </tr>
        <tr>
          <td>Organizational and Offering Expenses (1)</td>
          <td>{props.organizational_offering_expenses}</td>
        </tr>
        <tr>
          <td>Capital Improvements</td>
          <td>{props.capital_improvements}</td>
        </tr>
      </tbody>
    </Table>
  );
}

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  
  border-radius: 3px;
  transition: all 150ms;
`

const Body = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:#F0F2F5;

`

const Tbackground = styled.div`
 
`

const TopBackground1 = styled.img`
  
`

const TopBackground = styled.img`
  
`

const PText = styled.p`
  text-align: center;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-style: normal;

  
`

const Logo = styled.img`
 
`

const TopBackground2 = styled.img`
  
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  text-align: center;
`

const HText = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size:75px;
  text-align: center;
`

const Hero = styled.div`
  
`
const Hero2 = styled.div`
  
`

const Hero3 = styled.div`
  height:519px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
  
`

const BackButton = styled.label`
  // backgroundImage:arrow_outline;
  background-image: url(${arrow_outline});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: "#4D566F";

  background-color: white;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`

const Label = styled.label`
  
`

const Input = styled.input`
  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`
