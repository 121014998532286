import { functions, isEqual, omit } from 'lodash'
import React, { useState, useEffect, useRef } from 'react'

import useGooglePlaceAutoComplete from "../components/google_place_autocomplete"

function Map({ options, onMount, className, onMountProps }) {
  const ref = useRef()
  const [map, setMap] = useState()


  useEffect(() => {
    // The Map constructor modifies its options object in place by adding
    // a mapTypeId with default value 'roadmap'. This confuses shouldNotUpdate.
    // { ...options } prevents this by passing in a copy.
    const onLoad = () =>
      setMap(new window.google.maps.Map(ref.current, { ...options }))
    if (!window.google) {
      const script = document.createElement(`script`)
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAvRViF7bYdQ50zH5jUblEcZlyXndw7aJs`
      document.head.append(script)
      script.addEventListener(`load`, onLoad)
      return () => script.removeEventListener(`load`, onLoad)
    } else onLoad()
  }, [options])

  if (map && typeof onMount === `function`) onMount(map, onMountProps)

  return (
    <div
      style={{ height: `60vh`, margin: ` 1em 0`, borderRadius: ` 0.5em` }}
      {...{ ref, className }}
    />
  )
}

function shouldNotUpdate(props, nextProps) {
  const [funcs, nextFuncs] = [functions(props), functions(nextProps)]
  const noPropChange = isEqual(omit(props, funcs), omit(nextProps, nextFuncs))
  const noFuncChange =
    funcs.length === nextFuncs.length &&
    funcs.every(fn => props[fn].toString() === nextProps[fn].toString())
  return noPropChange && noFuncChange
}

export default React.memo(Map, shouldNotUpdate)

Map.defaultProps = {
  options: {
    center: { lat: 48, lng: 8 },
    zoom: 5,
  },
}

// import React, { Fragment, useState, useEffect, Component, useRef } from "react"
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from 'react-places-autocomplete';

// const SomeComponent = () => {
//   const [gmapsLoaded, setGmapsLoaded] = useState(false)

//   // This is how you do componentDidMount() with React hooks
//   useEffect(() => {
//     window.initMap = () => setGmapsLoaded(true)
//     const gmapScriptEl = document.createElement(`script`)
//     gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAvRViF7bYdQ50zH5jUblEcZlyXndw7aJs&libraries=places&callback=initMap`
//     document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
//   }, [])

//   return (
//     <div>
//       {gmapsLoaded && (
//         <PlacesAutocomplete />
//       )}
//     </div>
//   )
// }

// export default SomeComponent


