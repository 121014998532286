import React, { useState } from "react";
import styled from "styled-components"

const Form = ({ tableData, setTableData }) => {
  const [newData, setNewData] = useState({
    fullName: "",
    userName: "",
    address: "",
    phoneNumber: "",
    email: "",
    website: "",
    companyName: "",
  });

  const Change = (e) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  const Add = (e) => {
    e.preventDefault();
    setTableData([...tableData, { ...newData, id: Math.random() }]); // for giving the new data some random id
    setNewData({
      // for enptying the form
      fullName: "",
      userName: "",
      address: "",
      phoneNumber: "",
      email: "",
      website: "",
      companyName: "",
    });
  };

  return (
    <>
      <div >
        <div >
          <Hero3 >
            <CTAInput
              type="text"
              name="fullName"
              required
              placeholder="Enter full name..."
              onChange={Change}
              value={newData.fullName}
              
            />
            <CTAInput
              type="text"
              name="userName"
              required
              placeholder="Enter user name..."
              onChange={Change}
              value={newData.userName}
              
            />
            <CTAInput
              type="text"
              name="phoneNumber"
              required
              placeholder="Enter a phone number..."
              onChange={Change}
              value={newData.phoneNumber}
             
            />
{/*            <CTAInput
              type="text"
              name="website"
              required
              placeholder="Enter website..."
              onChange={Change}
              value={newData.website}
              
            />*/}
{/*            <CTAInput
              type="text"
              name="companyName"
              required
              placeholder="Enter company name..."
              onChange={Change}
              value={newData.companyName}
              
            />*/}
            <CTAInput
              type="email"
              name="email"
              required
              placeholder="Enter an email..."
              onChange={Change}
              value={newData.email}
              
            />
            
            <CTAButton2
              
              onClick={Add}
              type="submit"
            >
              Add
            </CTAButton2>
          </Hero3>
        </div>
      </div>
    </>
  );
};

export default Form;


const Hero3 = styled.table`
  // height:519px;
  // position:relative;
  // width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  // border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  // box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

`

const CTAButton2  = styled.button`
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8FA0B9;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }

 `