import React, { Fragment, useState, useEffect, Component, useRef } from "react"
import axios from "axios";
import Cookies from "universal-cookie";
import { Form, Button, Grid, Message, List } from "semantic-ui-react"
import styled from "styled-components"

import Checkbox from "@material-ui/core/Checkbox";

import arrow_outline from "../images/arrow-outline.svg"

import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget"
import AddressAutoComplete from "../components/AddressAutoComplete"


const PPMRiskFactors = props => {

  return (
    <div>
      <H1Text>
        II. RISK FACTORS
      </H1Text>

      <PText>
      THE MEMBERSHIP INTERESTS OFFERED BY THIS MEMORANDUM INVOLVE A HIGH
      DEGREE OF RISK AND SHOULD BE REGARDED AS A SPECULATIVE INVESTMENT.
      THEREFORE, ONLY PERSONS WHO CAN AFFORD THE LOSS OF THEIR ENTIRE
      INVESTMENT SHOULD CONSIDER PURCHASING THE MEMBERSHIP INTERESTS.
      PROSPECTIVE INVESTORS SHOULD CAREFULLY CONSIDER THE FOLLOWING RISK
      FACTORS, TOGETHER WITH OTHER INFORMATION CONTAINED IN THIS
      MEMORANDUM. 
      </PText>

      <P1Text>
         1. General Risks of Investing in Real Estate
      </P1Text>

      <PText>
      Investments in real property are subject to varying degrees of risk depending on such factors as general
      and local economic climates and real estate conditions. Revenues and property values may be
      adversely affected in light of such factors. The value of the Property is also likely to be affected by
      local growth rates and demand for residential or commercial real estate of the same type. In addition,
      real estate values may be adversely affected by applicable laws, including tax laws, interest rate levels,
      and availability of financing. Although the Company believes that investment in the Property is sound,
      no assurance can be given that it will be profitable or that investors will ever receive a return of all or
      any portion of their initial investment.
      </PText>

      <P1Text>
         2. Lack of Operating History
      </P1Text>

      <PText>
      The Company was organized in {props.state} in {props.state}. The Company is in the development stage and
      could thus experience a wide variety of delays, problems, and difficulties that are frequently
      encountered by new enterprises. The Company does not have an operating performance that can be
      historically evaluated. The Manager of the Company, Cloud Castles, Inc., was formed in December
      2019.
      </PText>

      <P1Text>
         3. Construction and Development Uncertainties
      </P1Text>

      <PText>
        The Company may make improvements to the Property at the Manager’s discretion. In the event the
        Company decides to undertake construction to the site, a significant portion of the Company’s assets
        may be used to finance the construction of such improvements. In general, construction involves many
        uncertainties and contingencies relating to timing, costs and regulatory compliance. In such event,
        construction cost overruns or delays could have a material adverse effect on the Company. Although
        the Company has included a modest reserve to allow for problems attendant to a budget overrun, if the
        actual construction/development costs of the project exceed anticipated costs, the Company may seek
        additional debt funding through borrowing in excess of its projected borrowing needs. If the Company
        obtains such loans, they shall be repaid with interest, prior to the return of capital contributions or
        distribution of returns to the investors. Such further funding may not be available. As a result, the
        investors’ return of capital contributions or participation in profits may be delayed.
      </PText>

      <P1Text>
         4. Illiquidity of Real Estate Investments and Lease Facilities
      </P1Text>

      <PText>
        Real estate investments are relatively illiquid so the Company may have a limited ability to react
        promptly in response to changes in economic or other conditions. For instance, if the Company is
        unable to rent the Property or is otherwise unsuccessful in raising rents to market rates, the cash flow
        and operations of the Company would be adversely affected. In the case of insufficient cash flow,
        the investors may need to make additional capital contributions in order to prevent their interest in
        the Company from becoming diluted. In addition, the Company’s ability to raise the current rents of
        the Property’s tenants to market rents is dependent on continuing stable and favorable market
        conditions. If market conditions are unfavorable, the Company may not be able to sufficiently raise
        rents and still maintain tenant occupancy levels sufficient to generate revenues to meet projections.
      </PText>

      <P1Text>
         5. Effect of Uninsured Loss
      </P1Text>

      <PText>
        The Company will carry comprehensive property, liability, fire, wind, extended coverage, and rental
        loss insurance with respect to the Property with insured limits and policy specifications that the
        Manager believes are customary for similar properties. However, there exist certain types of losses
        (e.g., due to natural disaster or war) which may either be uninsurable or not economically feasible. If
        an uninsured loss were to occur, the Company could lose its invested capital and anticipated profits
        from the Property.
      </PText>

      <P1Text>
         6. Lack of Diversification
      </P1Text>

      <PText>
        The Company’s assets will not be diversified and will be geographically concentrated since the
        Company’s asset will be the Property. Thus, the Company’s operating performance will be affected
        by the local economic climate, local real estate conditions, and other factors specific to the area in
        which the Property is located, including applicable zoning laws and the availability of financing.
      </PText>

      <P1Text>
         7. Effects of COVID-19
      </P1Text>

      <PText>
        In December 2019, the 2019 novel coronavirus (COVID-19) surfaced. The World Health
        Organization declared a global emergency on January 30, 2020 with respect to the outbreak then
        characterized it as a pandemic on March 11, 2020. The outbreak has spread worldwide, causing
        companies and many jurisdictions to impose restrictions, such as quarantines, closures, cancellations
        and travel restrictions. While these effects are expected to be temporary, the duration of the business
        disruptions and related financial impact cannot be reasonably estimated at this time, and it is possible
        these effects will negatively impact the Company’s performance. If a new outbreak occurs in the
        region in which the Company acquires real property, it could result in the decline in economic
        activity in that region, a decrease in the willingness of the general population to travel, staff
        shortages, reduced tenant traffic, mobility restrictions and other quarantine measures, supply
        shortages, increased government regulation, and the quarantine or contamination of the Property.
        Contagion in the Property or the region in which the Property is located could negatively impact the
        Property's occupancy, its reputation or attractiveness of that region. An outbreak may affect the
        businesses of the Company’s tenants, and thereby such tenants' ability to meet their payment
        obligations. All of these occurrences may have a material adverse effect on the business, financial
        condition and results of operations of the Company. Moreover, the Company may incur expenses or
        delays relating to such events outside of its control, which could have a material adverse impact on
        its business, operating results and financial condition. At this point, the Company does not expect
        the outbreak to have a material adverse impact on its operations and performance. However, the
        impact of a future outbreak is highly uncertain and cannot be predicted, and there is no assurance that
        such an outbreak will not have a material adverse impact on the future results of the Company. The
        extent of the impact, if any, will depend on developments outside of the Company’s control.
      </PText>

      <P1Text>
         8. Competition
      </P1Text>

      <PText>
        Numerous other real estate investors and developers may compete with the Company in seeking
        financing, tenants, and/or buyers.
      </PText>

      <P1Text>
         9. Environmental Considerations
      </P1Text>

      <PText>
        Under various federal, state and local environmental laws, ordinances, and regulations, a current or
        previous owner or operator of real property may become liable for the costs of investigation,
        removal, and remediation of hazardous or toxic substances on, under, in or migrating from such
        property. Such laws may impose liability without regard to whether the owner or operator knew of,
        or was responsible for, the presence of such hazardous or toxic substances. The presence of
        hazardous or toxic substances, or the failure to remediate properly such substances when present,
        may adversely affect the Company’s ability to sell or rent the Property or to borrow using the
        Property as collateral. The Property may contain underground storage tanks which are subject to
        strict laws and regulations designed to prevent leakage or other released of hazardous substances into
        the environment. The addition, the presence of hazardous substances on a property could result in
        personal injury or similar claims by private plaintiffs. Such claims could result in costs or liabilities
        which could exceed the value of such property.
      </PText>

      <P1Text>
         10. Additional Financing May Be Required
      </P1Text>

      <PText>
        If the anticipated net proceeds to be derived from this Offering will not be sufficient to enable the
        Company to purchase the Property, the Company may need to borrow or raise additional funds in order
        for the Company to carry out its plans. The Company has no commitments from any lenders and there
        can be no assurance that any such commitments will be obtained on favorable terms, if at all. The
        Company will likely be required to provide collateral for any loans. Any financing will decrease the
        amount of assets that would be available to the Members if the Company became subject to bankruptcy
        or insolvency proceedings or liquidated, dissolved or wound up its affairs. If, in that situation, the
        Company is unable to obtain a loan, it would not have sufficient funds to begin operating its business
        and may have to dissolve, whereupon Members may experience the loss of a significant part or all of
        their investment in the Company. In the event the Company seeks to raise additional funds through
        the sale of additional membership interests, investors in this Offering may experience dilution of their
        equity invested in the Company.
        Although the Manager believes there is a reasonable basis for the assumptions on which revenue
        projections are based, there is no assurance that the Company’s revenue projections will be achieved
        or that the Company’s objectives will be met. Continued operations will depend upon the availability
        of available cash flow from operations from the Property. If operating revenues are insufficient to
        continue the Company’s operations, additional funds would have to be raised through equity or debt
        financing, which could dilute the existing Members’ interests in the Company or reduce their returns
        on investment.
      </PText>

      <P1Text>
         11. Success Dependent upon Management
      </P1Text>

      <PText>
        As in all business, the investor must examine the management of the Company to ascertain its
        experience and its ability to achieve the Company’s goals. The success of the Company will depend,
        to a large extent, upon the active participation of {props.manager_first_name} {props.manager_last_name}, the executive
        officers of {props.llc_name}, the Manager of the Company. The loss of their services would
        materially and adversely affect the continued development of the business of the Company. Further,
        the Company's success depends on its ability to attract, motivate and retain qualified personnel. Should
        the Company be unable to do so, its operation and growth prospects could be adversely affected. The
        Company does not have key man life insurance on any officer or the Manager.
      </PText>

      <P1Text>
         12. Arbitrary Offering Price
      </P1Text>

      <PText>
        The purchase price for the membership interest in this Offering was determined by the Company and
        does not necessarily bear any relationship to the Company's asset value or net worth. Factors
        considered by the Company in setting the purchase price include management's view of the potential
        worth of the Company and the percentage of ownership of the Company that management desires to
        sell, among others. Each prospective investor should make an independent evaluation of the fairness
        of the purchase price.
      </PText>

      <P1Text>
         13. Dilution/Liquidation
      </P1Text>

      <PText>
        The investors in this Offering will be providing all of the Company’s capital contributions and will be
        receiving 100% of the net profits interest of the Company initially. There is no assurance there would
        be sufficient assets, in the event of liquidation, to return to the investors the cost of their investment in
        the Company.
      </PText>

      <P1Text>
         14. Concentration of Ownership; Lack of Voting Rights for Members
      </P1Text>

      <PText>
        Pursuant to the terms of the Company’s Operating Agreement, the investors shall be permitted to vote
        only on those matters pertaining only to an amendment of the Operating Agreement of the Company,
        a dissolution or merger of the Company, and certain other limited events. See Section 5.2 and 5.5 of
        the Operating Agreement. Moreover, the Members can only remove the Manager upon the occurrence
        of certain events listed in the Operating Agreement.
      </PText>

      <P1Text>
         15. Lack of Liquidity - Absence of Public Market; Restrictions on Resale and Transfer of Membership Interests
      </P1Text>

      <PText>
        The membership interests offered hereby are being sold pursuant to the private placement exemptions
        of the Federal and State securities laws. This means the interests are not “registered” and are therefore
        “restricted.” As such, the interests may not be transferred unless done in compliance with said laws
        and the rules promulgated thereunder. There is no public market for the interests and one may not be
        available in the foreseeable future. The Company is under no obligation to register the interests.
        Moreover, transferability of the interests offered herein is limited by the terms of the Company’s
        Operating Agreement. As a result of the foregoing, investors should not expect to be able to liquidate
        any portion of their investment in the event of an emergency or for any other reason; investors should
        be prepared to hold their investment indefinitely. Prospective investors are required to read and sign
        the Company’s Operating Agreement prior to making any investment in the Company.
      </PText>

      <P1Text>
         16. Federal Income Tax Consequences.
      </P1Text>

      <PText>
        This Offering is not structured to provide federal and state income tax benefits. Distributions to the
        Members are made at the discretion of the Manager. The Members therefore may be required to pay
        income tax on Company income without receiving any cash with which to pay this liability. Each
        prospective investor is urged to consult with their tax advisor with respect to the complex federal and
        state income tax consequences of such an investment.
      </PText>

      <P1Text>
         17. Disclosure Regarding Forward-Looking Statements
      </P1Text>

      <PText>
        This Memorandum, including the documents incorporated by reference herein, contains forwardlooking statements within the meaning of section 27A of the Act and section 21E of the Securities and
        Exchange Act of 1934. All forward-looking statements included in this document or incorporated by
        reference herein are based on information available to the Company on the date hereof, and the
        Company assumes no obligation to update any such forward-looking statements. These forwardlooking statements, including without limitation statements regarding the Company’s expectations,
        beliefs, intentions or strategies regarding the future, involve risks and uncertainties. The Company’s
        actual results could differ materially from those anticipated in these forward-looking statements as a
        result of certain factors, including those set forth in “Risk Factors” and elsewhere in this Memorandum.      
      </PText>

      <P1Text>
        NOTE: IN ADDITION TO THE ABOVE RISKS, BUSINESSES ARE OFTEN SUBJECT TO
        RISKS NOT FORESEEN OR FULLY APPRECIATED BY MANAGEMENT. IN
        REVIEWING THIS DISCLOSURE DOCUMENT POTENTIAL INVESTORS SHOULD
        KEEP IN MIND OTHER POSSIBLE RISKS THAT COULD BE IMPORTANT.
      </P1Text>

    </div>
  )
}

export default PPMRiskFactors;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  
  border-radius: 3px;
  transition: all 150ms;
`

const Body = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:#F0F2F5;

`


const PText = styled.p`
  text-align: center;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-style: normal;

  
`
const P1Text = styled.p`
  text-align: left;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-weight: 600;
`

const Logo = styled.img`
 
`

const TopBackground2 = styled.img`
  
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  text-align: center;
`

const HText = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size:75px;
  text-align: center;
`

const Hero = styled.div`
  
`
const Hero2 = styled.div`
  
`

const Hero3 = styled.div`
  height:519px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
  
`

const BackButton = styled.label`
  // backgroundImage:arrow_outline;
  background-image: url(${arrow_outline});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: "#4D566F";

  background-color: white;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`

const Label = styled.label`
  
`

const Input = styled.input`
  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`
