import React, { Fragment, useState, useEffect, Component, useRef } from "react"
import axios from "axios";
import Cookies from "universal-cookie";
import { Form, Button, Grid, Message, List } from "semantic-ui-react"
import styled from "styled-components"

import Checkbox from "@material-ui/core/Checkbox";

import arrow_outline from "../images/arrow-outline.svg"

import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget"
import AddressAutoComplete from "../components/AddressAutoComplete"

import TableApp from "../components/table/TableApp"


const LLCInput = props => {
  const [emailError, setEmailError] = React.useState("")

  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [password, setPassword] = useState("");
  const [signUpUsername, setSignUpUsername] = useState("");
  const [signUpPW, setSignUpPW] = useState("");
  const [signUpPW2, setSignUpPW2] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [response, setResponse] = useState(null);

  let userObj = {}
  // const [userObj,setUserObj] = useState({});

  const cookies = new Cookies();

  const update = e => {
    props.update(e.target.name, e.target.value)
    // console.log(e.target.name, e.target.value)
    userObj[e.target.name] = e.target.value

// Temporary password

  }

  const handleSumit = (event) => {
    // use to make sure page isn't reloaded when we send off content
    event.preventDefault();

    // var randomNumber = Math.random().toString(36).substr(2, 8);
    // setSignUpPW2(randomNumber);
    // setSignUpPW(randomNumber);

    const loginData = {
      // password1: randomNumber,
      // password2: randomNumber,
      username: email,
      email: email,
    };

    const userData = {
      first_name: first_name,
      last_name: last_name,
      phoneNumber: phoneNumber,
      email: email,
    };

    console.log(userData);
    console.log(loginData);


    };

  const nextSteppers = () => {
    // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // if (re.test(userObj.email)) {
      props.nextStep()
    // } else {
    //   console.log(email)

    //   setEmailError("Please set a valid email")
    // }
  }

  const previousSteppers = () => {
    props.previousStep()
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log(checked)
  };


  return (
    <Hero>
      <H1Text>LLC Creation</H1Text>
      <PText>
        Setup all your legal paperwork, register your LLC and pool funding from investors all on one easy to use platfrom.
      </PText>
      {/*<PlacesPage />*/}
      <PText>Business</PText>
      <Hero2>
        {/*<PText>LLC Name</PText>*/}
        {/*<CloudinaryUploadWidget />*/}
        <CTAInput
          type="text"
          className="form-control"
          name="llc_name1"
          placeholder="Business Name"
          onChange={update}
        />

        {/*<PText>State</PText>*/}
        <CTAInput
          type="text"
          className="form-control"
          name="llc_name2"
          placeholder="Backup Business Name"
          onChange={update}
        />
        <span>Include the proper ending (Limited Liability Company, LLC, Ltd, etc..)</span>
        <AddressAutoComplete endpoint={`${process.env.CASTLE_API_URL}`} placeholder={"Physical Business Address"}/>
        <PText>Register</PText>
{/*        <Checkbox
            checked={setChecked(checked)}
            onChange={this.handleCheckboxChange}
          />*/}
        <Checkbox checked={checked} onChange={handleChange} />
        <span>Cloud Castles is the Registered Agent</span>

        <Checkbox checked={checked} onChange={handleChange} />
        <span>Myself as the Registered Agent</span>

        <Checkbox checked={checked} onChange={handleChange} />
        <span>Another Person is the Registered Agent</span>

        <CTAInput
          type="text"
          className="form-control"
          name="first_name"
          placeholder="First Name"
          onChange={event => setFirstName(event.target.value)}
        />

        {/*<PText>Last Name</PText>*/}
        <CTAInput
          type="text"
          className="form-control"
          name="last_name"
          placeholder="Last Name"
          onChange={event => setLastName(event.target.value)}
        />
        {/*<PText>Phone Number</PText>*/}
        <CTAInput
          type="tel"        
          className="form-control"
          name="business_type"
          placeholder="This property is a rental"
          onChange={event => setPhoneNumber(event.target.value)}
        />

        <TableApp title={"LLC Members"} />

        <Checkbox checked={checked} onChange={handleChange} />
        <span>Company is managed by Cloud Castles</span>
        <span>(Being managed by Cloud Castles does not include property management)</span>

        <Checkbox checked={checked} onChange={handleChange} />
        <span>Company is managed by me</span>


      </Hero2>
      <CTAButton onClick={nextSteppers}>Next Step</CTAButton>
      <BackButton  onClick={previousSteppers}>Back</BackButton>
      <SkipButton  onClick={nextSteppers}>Skip</SkipButton>
    </Hero>
  )
}

export default LLCInput;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => props.checked ? 'salmon' : 'papayawhip'};
  border-radius: 3px;
  transition: all 150ms;
`

const Body = styled.div`
  // height: 150vh;
  margin-top:300px;
  margin-bottom:100px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-evenly;
  // align-items:center;
  // background-color:#F0F2F5;

`

const Tbackground = styled.div`
 
`

const TopBackground1 = styled.img`
  
`

const TopBackground = styled.img`
  
`

const PText = styled.p`
  text-align: center;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-style: normal;

  
`

const Logo = styled.img`
 
`

const TopBackground2 = styled.img`
  
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
`

const Hero = styled.div`
  margin-left:10%;
  margin-right:10%;
`
const Hero2 = styled.div`
  
`

const Hero3 = styled.div`
  height:519px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
  
`

const SkipButton = styled.label`
  // backgroundImage:arrow_outline;
  // background-image: url(${arrow_outline});
  // background-repeat: no-repeat;
  // background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: right;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: right; 
  margin-right: 30px;
  margin-left: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: clear;

  background-color: clear;
  // box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  // border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`



const BackButton = styled.label`
  // backgroundImage:arrow_outline;
  background-image: url(${arrow_outline});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: "#4D566F";

  background-color: white;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`

const Label = styled.label`
  
`

const Input = styled.input`
  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`
