import React, { Fragment, useState, useEffect, Component, useRef } from "react"
import axios from "axios";
import Cookies from "universal-cookie";
import styled from "styled-components"

import arrow_outline from "../images/arrow-outline.svg"

import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget"
import AddressAutoComplete from "../components/AddressAutoComplete"

import {addCommas,removeNonNumeric} from "../components/Helpers"

import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { tokens } from "./theme";

import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const PropertyInput = props => {
  // const colors = tokens(theme.palette.mode);
  const theme = useTheme();

  const [emailError, setEmailError] = React.useState("")

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [password, setPassword] = useState("");
  const [signUpUsername, setSignUpUsername] = useState("");
  const [signUpPW, setSignUpPW] = useState("");
  const [signUpPW2, setSignUpPW2] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [response, setResponse] = useState(null);

  const [mainImage,set_mainImage] = useState("")
  const [address1,set_address1] = useState("")
  const [address2,set_address2] = useState("")
  const [city,set_city] = useState("")
  const [state,set_state] = useState("")
  const [zip,set_zip] = useState("")
  const [country,set_country] = useState("")
  const [start_date,set_start_date] = useState("")
  
  const [bedroom,set_bedroom] = useState(0)
  const [bathroom,set_bathroom] = useState(0.0)
  const [sqft,set_sqft] = useState("")  
  const [maximum_offering, set_maximum_offering] = useState("")
  const [percentage_towards_renovations, set_percentage_towards_renovations] = useState("")
  const [subscription_period, set_subscription_period] = useState("")
  const [post_closing, set_post_closing] = useState("")
  const [down_payment, set_down_payment] = useState("")
  const [organizational_offering_expenses, set_organizational_offering_expenses] = useState("")
  const [capital_improvements, set_capital_improvements] = useState("")

  let userObj = {}
  // const [userObj,setUserObj] = useState({});

  const cookies = new Cookies();

  const update = e => {
    // props.update(e.target.name, e.target.value)
    // console.log(e.target.name, e.target.value)
    userObj[e.target.name] = e.target.value

    console.log(userObj)
    // console.log("first_name")
    // console.log(userObj["first_name"])

    // console.log("last_name")
    // console.log(userObj["last_name"])

    // console.log("email")
    // console.log(userObj["email"])

    // setEmail(userObj["email"])
    // setFirstName(userObj["first_name"])
    // setLastName(userObj["last_name"])
    // setPhoneNumber(userObj["phoneNumber"])

// Temporary password

  }

  const handleSumit = (event) => {
    // use to make sure page isn't reloaded when we send off content
    event.preventDefault();

    // var randomNumber = Math.random().toString(36).substr(2, 8);
    // setSignUpPW2(randomNumber);
    // setSignUpPW(randomNumber);

    const loginData = {
      // password1: randomNumber,
      // password2: randomNumber,
      username: email,
      email: email,
    };

    const userData = {
      first_name: first_name,
      last_name: last_name,
      phoneNumber: phoneNumber,
      email: email,
    };

    console.log(userData);
    console.log(loginData);


    };

  const currentcyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  const keyPressHanlder = (event) => {
    const { key } = event;
    set_capital_improvements((prevValue) =>
      key !== "Backspace"
        ? !Number.isNaN(parseInt(key)) || key === "," || key === "."
          ? prevValue + key
          : prevValue
        : prevValue.substring(0, prevValue.length - 1)
    );
  };

  const handlePageChange = (event) => {
    console.log("handlePageChange")
  }

  const bedroomPlus = (event) => {
    console.log("handlePageChange")
    set_bedroom(bedroom + 1)
  }

 const bedroomMinus = (event) => {
    if (bedroom > 0) {
      set_bedroom(bedroom - 1)  
    }    
  }

  const bathroomPlus = (event) => {
    console.log("handlePageChange")
    set_bathroom(bathroom + 0.5)
  }

 const bathroomMinus = (event) => {
    if (bathroom > 0.0) {
      set_bathroom(bathroom - 0.5)
    }    
  }

  const nextSteppers = () => {
    // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // if (re.test(userObj.email)) {
      props.nextStep() 
    // } else {
    //   console.log(email)

    //   setEmailError("Please set a valid email")
    // }
  }

  const previousSteppers = () => {
    props.previousStep()
  }

  return (
    <Hero>
      <H1Text>Property Details</H1Text>
      <PText>
        Provide the details of your property
      </PText>
      {/*<PlacesPage />*/}
      <Hero2>
        {/*<PText>LLC Name</PText>*/}
        <CloudinaryUploadWidget />
        <span>Add The Investment Address</span>
        <AddressAutoComplete />
        <span>Business Name</span>
        <CTAInput
          type="text"
          className="form-control"
          name="llc_name"
          placeholder="Business Name"
          onChange={update}
        />

        <span>Bedroom</span>
        <IconButton>
          <RemoveCircleOutlineOutlinedIcon
            onClick={bedroomMinus}
          />
        <span>{bedroom}</span>
        </IconButton>
        <IconButton>
        <AddCircleOutlineOutlinedIcon
          onClick={bedroomPlus}
        />
        </IconButton>

        <span>bathroom</span>

        
        <IconButton>
          <RemoveCircleOutlineOutlinedIcon
            onClick={bathroomMinus}
          />
        </IconButton>
        <span>{bathroom}</span>
        <IconButton>
        <AddCircleOutlineOutlinedIcon
          onClick={bathroomPlus}
        />
        </IconButton>
{/*        <CTAInput
          type="text"
          className="form-control" 
          name="bedroom"
          placeholder="bedroom"
          onChange={update}
        />
        <CTAInput
          type="text"
          className="form-control" 
          name="bathroom"
          placeholder="bathroom"
          onChange={update}
        />*/}
        <CTAInput
          type="text"
          className="form-control" 
          name="sqft"
          placeholder="sqft"
          onChange={(e) => set_sqft(e.target.value)}
          value={sqft}
        />
        <CTAInput
          // type="text"
          type="number"
          // name="phone"
          // placeholder="Phone number"
          onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
          className="form-control" 
          name="maximum_offering"
          placeholder="maximum_offering"

          onChange={update}
        />
        <CTAInput
          type="text"
          className="form-control" 
          name="percentage_towards_renovations"
          placeholder="percentage_towards_renovations"
          onChange={update}
        />
        <CTAInput
          type="text"
          className="form-control" 
          name="subscription_period"
          placeholder="subscription_period"
          onChange={update}
        />
        <CTAInput
          type="text"
          className="form-control" 
          name="post_closing"
          placeholder="post_closing"
          onChange={update}
        />
        <CTAInput
          type="text"
          className="form-control" 
          name="down_payment"
          placeholder="down_payment"
          onChange={update}
        />
        <CTAInput
          type="text"
          className="form-control" 
          name="organizational_offering_expenses"
          placeholder="organizational_offering_expenses"
          onChange={update}
        />
        <span style={{ color: "black" }}>Capital Improvements</span>
        <CTAInput
          type="text"
          className="form-control" 
          name="capital_improvements"
          // placeholder="capital_improvements"
          // value={capital_improvements}
          // onChange={handleChange}
          onKeyDown={keyPressHanlder}
          placeholder={currentcyFormat.format("")}
          value={capital_improvements !== "" ? currentcyFormat.format(capital_improvements) : ""}
        />

        <span style={{ color: "red" }}>{emailError}</span>
      </Hero2>
      <CTAButton onClick={nextSteppers}>Next Step</CTAButton>
      <BackButton  onClick={previousSteppers}>Back</BackButton>
    </Hero>
  )
}

export default PropertyInput;

const Body = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:#F0F2F5;

`

const Tbackground = styled.div`
 
`

const TopBackground1 = styled.img`
  
`

const TopBackground = styled.img`
  
`

const PText = styled.p`
  text-align: center;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-style: normal;

  
`

const Logo = styled.img`
 
`

const TopBackground2 = styled.img`
  
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
`

const Hero = styled.div`
  margin-left:10%;
  margin-right:10%;
`
const Hero2 = styled.div`
  
`

const Hero3 = styled.div`
  height:519px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
  
`

const BackButton = styled.label`
  // backgroundImage:arrow_outline;
  background-image: url(${arrow_outline});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: "#4D566F";

  background-color: white;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`

const Label = styled.label`
  
`

const Input = styled.input`
  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`
