import React, { Fragment, useState, useEffect, Component, useRef } from "react"
import axios from "axios";
import Cookies from "universal-cookie";
import { Form, Button, Grid, Message, List } from "semantic-ui-react"
import styled from "styled-components"

import Checkbox from "@material-ui/core/Checkbox";

import arrow_outline from "../images/arrow-outline.svg"

import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget"
import AddressAutoComplete from "../components/AddressAutoComplete"


const PPMLegalDisclosures = props => {

  return (
    <div>
      <H1Text>IMPORTANT LEGAL DISCLOSURES – PLEASE READ</H1Text>

      <PText>
      This Offering Memorandum was prepared by {props.llc_name} (the
      “Offeror”). The OFFEROR does not make any representation or warranty, express or
      implied, as to the completeness or the accuracy of the material contained in this Offering
      Memorandum.
      </PText>
      <PText>
      This Offering Memorandum is utilized as an attempt to determine interest only and is not
      an offer to sell securities. The OFFEROR reserves the right, at its sole discretion, to reject
      any or all expressions of interest and expressly reserves the right, at its sole discretion, to
      terminate discussions with any person or entity at any time with or without notice. The
      OFFEROR shall have no legal commitment or obligations to any person or entity reviewing
      this Offering Memorandum.
      This Offering Memorandum is confidential. By accepting the Offering Memorandum, you
      agree (i) that you will hold and treat the Offering Memorandum and its contents in the
      strictest confidence, (ii) that you will not photocopy, scan, duplicate or email any part of
      the Offering Memorandum, (iii) that you will not disclose the Offering Memorandum or
      any of its contents to any other person or entity without the prior written authorization of
      the OFFEROR, and (iv) that you will not use the Offering Memorandum in any fashion or
      manner detrimental to the interest of the OFFEROR.
      The information in this Offering Memorandum has been obtained from sources believed
      reliable. While the OFFEROR does not doubt its accuracy, it has not verified it and makes
      no guarantee, warranty, or representation about it. It is your responsibility to independently
      confirm its accuracy and completeness. Any projections, opinions, assumptions, or
      estimates used are for example only and do not represent the current or future performance
      of the company to be formed. Any projections contained in the Offering Memorandum,
      financial or otherwise, are estimates only, based upon assumptions which may be incorrect,
      and there can be no assurance as to their accuracy. The achievement of any financial
      projection may be affected by fluctuating economic conditions, and other factors, and is
      dependent on the occurrence of future events that cannot be assured.
      </PText>

      <PText>
      The undersigned has read, accepts and agrees to the above terms.
      </PText>

      <H1Text>Date SIGNATURE </H1Text>

      <PText>
        DISCLOSURES
      </PText>

      <PText>
        THE DELIVERY OF THIS OFFERING MEMORANDUM SHALL CONSTITUTE AN OFFER TO
        AN INVESTOR ONLY AFTER COMPLETION OF A CONFIDENTIAL INVESTOR
        SUITABILITY QUESTIONNAIRE AND ITS REVIEW AND APPROVAL BY
        REPRESENTATIVES OF THE COMPANY. THIS CONFIDENTIAL OFFERING
        MEMORANDUM DOES NOT CONSTITUTE AN OFFER OR SOLICITATION IN ANY
        JURISDICTION TO ANY PERSON TO WHOM IT IS UNLAWFUL TO MAKE SUCH OFFER OR
        SOLICITATION, AND NO PRIOR DOCUMENT, WHETHER ISSUED BY THE COMPANY OR
        BY ANY OTHER PARTY, CONSTITUTES SUCH AN OFFER OR SOLICITATION.
        NO PERSON HAS BEEN AUTHORIZED TO GIVE OR FURNISH ANY INFORMATION OTHER
        THAN THAT CONTAINED IN THIS OFFERING MEMORANDUM OR TO MAKE ANY
        REPRESENTATION IN CONNECTION WITH THE OFFERING MADE HEREBY, AND, IF
        GIVEN OR MADE, SUCH OTHER INFORMATION OR REPRESENTATIONS MUST NOT BE
        RELIED UPON AS HAVING BEEN AUTHORIZED BY THE COMPANY. THE COMPANY
        DISCLAIMS ANY AND ALL LIABILITIES FOR REPRESENTATIONS OR WARRANTIES,
        EXPRESS OR IMPLIED, CONTAINED IN, OR OMISSIONS FROM, THIS MEMORANDUM OR
        ANY OTHER WRITTEN OR ORAL COMMUNICATION TRANSMITTED OR MADE
        AVAILABLE TO THE RECIPIENT. EACH INVESTOR WILL BE ENTITLED TO RELY SOLELY
        ON THOSE REPRESENTATIONS CONTAINED IN THIS OFFERING MEMORANDUM.
        EACH OFFEREE MAY, IF HE OR SHE SO DESIRES, MAKE INQUIRIES OF THE COMPANY
        REPRESENTATIVES WITH RESPECT TO THE COMPANY’S BUSINESS OR ANY OTHER
        MATTERS SET FORTH HEREIN, AND MAY OBTAIN ANY ADDITIONAL INFORMATION
        WHICH SUCH PERSON DEEMS TO BE NECESSARY IN ORDER TO VERIFY THE
        ACCURACY OF THE INFORMATION CONTAINED IN THIS MEMORANDUM AND TO
        MAKE AN INVESTMENT DECISION (TO THE EXTENT THAT THE COMPANY POSSESSES
        SUCH INFORMATION OR CAN ACQUIRE IT WITHOUT UNREASONABLE EFFORT OR
        EXPENSE). IN CONNECTION WITH SUCH INQUIRY, ANY DOCUMENTS SUCH OFFEREE
        WISHES TO REVIEW WILL BE MADE AVAILABLE FOR INSPECTION AND COPYING OR
        PROVIDED, UPON REQUEST, SUBJECT TO THE OFFEREE’S AGREEMENT TO MAINTAIN
        SUCH INFORMATION IN CONFIDENCE AND TO RETURN THE SAME TO THE COMPANY
        IF THE RECIPIENT DOES NOT PURCHASE THE UNITS OFFERED HEREUNDER AND, AT
        THE COMPANY’S DISCRETION, THE EXECUTION OF A CONFIDENTIALITY AGREEMENT.
        ANY SUCH INQUIRIES OR REQUESTS FOR ADDITIONAL INFORMATION OR
        DOCUMENTS SHOULD BE MADE IN WRITING TO THE COMPANY AT THE ADDRESS FOR
        THE COMPANY SET FORTH HEREIN.
        INVESTORS WHO PURCHASE UNITS HEREBY OFFERED WILL THEREBY BECOME
        MEMBERS OF A FLORIDA LIMITED LIABILITY COMPANY. THE TAX CONSEQUENCES
        OF AN INVESTMENT IN A LIMITED LIABILITY COMPANY ARE COMPLEX AND, IN SOME
        ASPECTS, UNCERTAIN, AND WILL DEPEND LARGELY UPON AN INVESTOR’S
        CIRCUMSTANCES. INVESTORS ARE ADVISED TO CONSULT THEIR OWN LEGAL, TAX,
        AND FINANCIAL ADVISORS WITH REGARD TO THIS OFFERING.
        FOR FURTHER DISCUSSION OF RISKS OF THE OFFERING, SEE “RISK FACTORS”.
      </PText>
    </div>
  )
}

export default PPMLegalDisclosures;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  
  border-radius: 3px;
  transition: all 150ms;
`

const Body = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:#F0F2F5;

`

const Tbackground = styled.div`
 
`

const TopBackground1 = styled.img`
  
`

const TopBackground = styled.img`
  
`

const PText = styled.p`
  text-align: center;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-style: normal;

  
`

const Logo = styled.img`
 
`

const TopBackground2 = styled.img`
  
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  text-align: center;
`

const HText = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size:75px;
  text-align: center;
`

const Hero = styled.div`
  
`
const Hero2 = styled.div`
  
`

const Hero3 = styled.div`
  height:519px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
  
`

const BackButton = styled.label`
  // backgroundImage:arrow_outline;
  background-image: url(${arrow_outline});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: "#4D566F";

  background-color: white;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`

const Label = styled.label`
  
`

const Input = styled.input`
  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`
