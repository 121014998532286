import React, { Fragment, useState, useEffect, Component, useRef } from "react"
import axios from "axios";
import Cookies from "universal-cookie";
import { Form, Button, Grid, Message, List } from "semantic-ui-react"
import styled from "styled-components"

import arrow_outline from "../images/arrow-outline.svg"

import {chatHitCCBE,hitCCBE, sendCloudCastles,getBothTokens,getToken2 } from '../components/Helpers'

import CloudinaryUploadWidget from "../components/CloudinaryUploadWidget"
import AddressAutoComplete from "../components/AddressAutoComplete"

import profile_anon from "../images/profile_anon.png"

const ProfileInput = props => {
  const [emailError, setEmailError] = React.useState("")

  const [bio, setBio] = useState("");
  const [count, setCount] = useState(0);
  const [count_color, set_count_color] = useState("black");

  const [imageUrlParent, setimageUrlParent] = useState("");
  // const [email, setEmail] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState(0);
  // const [password, setPassword] = useState("");
  // const [signUpUsername, setSignUpUsername] = useState("");
  // const [signUpPW, setSignUpPW] = useState("");
  // const [signUpPW2, setSignUpPW2] = useState("");
  // const [first_name, setFirstName] = useState("");
  // const [last_name, setLastName] = useState("");
  // const [response, setResponse] = useState(null);

  let userObj = {}
  // const [userObj,setUserObj] = useState({});

  const cookies = new Cookies();

  const update = e => {
    console.log(e.target.name, e.target.value)

    setBio(e.target.value)

    console.log(e.target.value.length)
    setCount(bio.length)
    // console.log("first_name")
    console.log(userObj["bio"])

    if (bio.length >= 20){
      set_count_color("black")
    } else {
      set_count_color("red")
    }


  }

  const handleSumit = (event) => {
    // use to make sure page isn't reloaded when we send off content
    event.preventDefault();

    };

  const nextSteppers = () => {

    if (bio.length >= 20) {

      var user_bio =  JSON.stringify({
        user_bio:bio,
        profile_pic:imageUrlParent
      });
      
      console.log("user_bio",user_bio)
      hitCCBE(`${process.env.CASTLE_API_URL}/v1/web_registration/`,user_bio,"PUT").then(response => 
        console.log(response)
      );
      

      props.nextStep()
      
    } else {

      setEmailError("Please add a longer bio")
    }
  }

  const previousSteppers = () => {
    props.previousStep()
  }

  const parentCallback =  function(imageUrl) {
    console.log("imageUrl")
    console.log(imageUrl)
        // do something with value in parent component, like save to state
    }

    const daFuck = () =>{
      console.log("daFuck")
      getToken2()

    }

  return (
    <Hero>
      <H1Text>Create Your Investor Profile</H1Text>
      <PText>
        This helps build trust with other investors.
      </PText>
      <CloudinaryUploadWidget setimageUrlParent={setimageUrlParent} profileImg={profile_anon}/>
      <Hero2>
        {/*<PText>LLC Name</PText>*/}
        <span style={{ color: count_color }}>{count}/500</span>
        <CTATextArea
          type="text"
          className="form-control"
          name="bio"
          placeholder="I've worked on investments properties as..."
          onChange={update}
        />
        <span style={{ color: "red" }}>{emailError}</span>

        {/*<PText>State</PText>*/}
{/*        <CTAInput
          type="text"
          className="form-control"
          name="last_name"
          placeholder="Last Name"
          onChange={update}
        />
        <CTAInput
          type="tel"        
          className="form-control"
          name="total_amount"
          placeholder="$10000"
          onChange={update}
        />*/}
        <PText>Mailing Address:</PText>
          <AddressAutoComplete placeholder={"Mailing address (this is not public)"} endpoint={`${process.env.CASTLE_API_URL}/v1/web_registration/`} />
{/*        <CTAInput
          type="text"
          className="form-control"
          name="email"
          placeholder="Email"
          onChange={update}
          // error={emailError}
        />*/}
        
      </Hero2>
      <CTAButton onClick={nextSteppers}>Next Step</CTAButton>
      {/*<CTAButton onClick={daFuck}>Get RToken</CTAButton>*/}
      {/*<BackButton  onClick={previousSteppers}>Back</BackButton>*/}
    </Hero>
  )
}

export default ProfileInput;

const Hero = styled.div`
  margin-left:10%;
  margin-right:10%;
`

const Body = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:#F0F2F5;

`

const Tbackground = styled.div`
 
`

const TopBackground1 = styled.img`
  
`

const TopBackground = styled.img`
  
`

const PText = styled.p`
  text-align: center;
  padding:10px;
  margin-right:40px;
  margin-left:40px;
  font-family: Poppins;
  font-style: normal;

  
`

const Logo = styled.img`
 
`

const TopBackground2 = styled.img`
  
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
`

const Hero2 = styled.div`
  
`

const Hero3 = styled.div`
  height:519px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media (max-width: 867px) {
    //   margin-top:70px;
    //   margin-left:  80px;
    // margin-right:  80px;
    
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  padding: 5px;
  font-family: Poppins;
  text-align: center; 
  margin-left: auto;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 16.1461px;
  // line-height: 24px;
  width:50%;
  justify-content: center;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
  
`

const BackButton = styled.label`
  // backgroundImage:arrow_outline;
  background-image: url(${arrow_outline});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  color: "#4D566F";

  background-color: white;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }  
`

const Label = styled.label`
  
`

const Input = styled.input`
  
`

const CTATextArea = styled.textarea`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`

const CTAInput = styled.input`
    // height: 40px;
    // width: 320px;
    // left: 152px;
    // top: 140px;
    margin:10px;
    border-radius: 24px;
    padding: 8px 16px 8px 16px;

  
`
